<template>
<v-container>
   <form>

      <v-row>
         <v-col cols="12">
            <v-text-field color="primary" outlined dense v-model="data.step.tableTo" label="Step Name" :hint="errors.tableTo?.join(' - ')" :error="(errors?.tableTo)?true:false" @input="onChange(data.step, 'tableTo')" persistent-hint></v-text-field>
         </v-col>
         
         <v-col cols="12" class="mb-n5">
            <v-select @focus="refreshCols()" item-color="primary" color="primary" multiple dense small-chips outlined label="Select The Columns To Keep" :items="data.step.tableFromColumns" item-text="pretty_name" item-value="column_name" v-model="data.step.tableToColumns" return-object :hint="errors.tableToColumns?.join(' - ')" :error="(errors?.tableToColumns)?true:false" @input="onChange(data.step, 'tableToColumns')" persistent-hint></v-select>
         </v-col>

         <v-col cols="12">
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
            </v-card-actions>
         </v-col>
      </v-row>
   </form>
</v-container>
</template>

<script>
import {
   emitData
} from '@/utils/eventBus'
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'

export default {
   name: 'RemoveColumnsNode',
   mixins: [formRules, nodeCode],
   props: {
      node: {
         type: Object,
         default: () => {
            return {}
         }
      }
   },
   data() {
      return {
         errors: {},
         data: {
            step: {},
            rules: {
               tableTo: {
                  rule: 'notEmpty'
               },
               tableToColumns: {
                  rule: 'notEmptyArray'
               }
            }
         }
      }
   },
   created() {

      this.processParentData();
      this.data.step.type = 'remove'

   },

   computed: {
      disabledRules() {
         let isDisabled = false;
         for (const key in this.errors) {
            if (this.errors[key] != null) {
               isDisabled = true
            }
         }
         if (
            !this.data.step.tableTo ||
            this.data.step.tableToColumns.length < 1
         ) {
            isDisabled = true
         }
         return isDisabled;
      },
   },
   methods: {

      emitDataAction() {



         // remove columns that don't have the correct column name
         const validColumns = this.data.step.tableFromColumns.map(x => x.column_name)
         this.data.step.tableToColumns = this.data.step.tableToColumns.filter(x => validColumns.includes(x.column_name))

         emitData('emit-data', this.data);
      }
   }
}
</script>
