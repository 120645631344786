import Vue from 'vue';

export const ACTIONS = {
  SNACKBAR: 'snackbar',
};

const EventBus = new Vue();

export const showSnackBar = (message, type) => {
  EventBus.$emit(ACTIONS.SNACKBAR, { message:message, type:type});
};

export const emitData = (ref, data) => {
  EventBus.$emit(ref, data);
};

export default EventBus;
