<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-select data-cy="baseDatasetSelect" color="primary" item-color="primary" v-model="data.step.tableFromComplete" class="pa-5" dense outlined label="Select Dataset" :items="allDatasets" persistent-hint></v-select>
            </v-col>
            <v-col cols="12">
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="data.node.data.status === 'unconfigured'" data-cy="saveButton" @click="emitDataAction" color="primary" :disabled="data.step.datasetId === null">Save</v-btn>
                    <v-btn v-if="data.node.data.status !== 'unconfigured'" data-cy="updateButton" @click="emitDataAction" color="primary" :disabled="data.step.datasetId === null">Update</v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
    </v-container>
</template>
    
<script>
import pipelinesApi from '@/services/pipelines';
import datasetsApi from '@/services/datasets'
import { emitData } from '@/utils/eventBus'
import {userStore} from '@/stores/UserStore';
const {v4: uuidv4} = require('uuid');

export default {

    name: 'BaseDatasetNode',

    props: {
        node:{ type: Object, default: () => {return {}}},
    },

    setup() {
        const user = userStore()
        return {
            user,
        }
    },

    data() {
        return {
            text: 'This is component A',
            dialog: false,
            allDatasets: [],
            data: {
                step: {
                    type: 'base',

                    schemaTo: `pipelines_${this.user.getCurrentWorkspace}`,
                    tableToDatabaseName: uuidv4().replace(/-/g, '_').toLowerCase(), 
                    tableTo: 'Base Dataset',
                    tableToColumns: null,

                    schemaFrom: null,
                    tableFromComplete: null,
                    tableFromDatabaseName: null,
                    tableFromColumns: null,
                    
                },
                node: this.node,
                status: 'unconfigured'
            },

        }
    },

    computed: {

        saveOrUpdate() {

            if (this.data.node.data.status === 'unconfigured') {
                return true
            } else {
                return false
            }

        },

    },


    created() {

        if(this.node.data.step != null){
            this.data.step = this.node.data.step
        }

        pipelinesApi.getAcyclicDatasets(this.user.getCurrentWorkspace, this.data.step.schemaTo, this.data.step.tableToDatabaseName)
            .then(res => {

                this.allDatasets = res.data.map(({ pretty_name, cyclic, schema, dataset_id, name}) => ({ 
                    text: pretty_name, 
                    disabled: cyclic,
                    value: {
                        schema,
                        dataset_id,
                        name
                    }, 
                }));
            })

      
    },
    methods: {
        
        emitDataAction(e) {

            e.preventDefault();
            
            // Get the columns from this dataset to pass to child nodes
            // Set the node name to the selected datasets pretty name

            this.data.step.schemaFrom = this.data.step.tableFromComplete.schema;
            this.data.step.datasetId = this.data.step.tableFromComplete.dataset_id;
            this.data.step.tableFromDatabaseName = this.data.step.tableFromComplete.name;

            this.data.step.workspaceId = this.user.getCurrentWorkspace;
            
            datasetsApi.getMapping(this.data.step.datasetId)
                .then(res => {
                    this.data.step['tableFromColumns'] = res.data;
                    const columns = [];
                    for (let i = 0; i < this.data.step.tableFromColumns.length; i++) {
                        columns.push({
                            column_name: this.data.step.tableFromColumns[i].display_name.replace(/\s/g, '_').toLowerCase(),
                            display_name: this.data.step.tableFromColumns[i].display_name,
                            pretty_name: this.data.step.tableFromColumns[i].pretty_name,
                            data_type_id: this.data.step.tableFromColumns[i].data_type_id,
                            mapping_id: this.data.step.tableFromColumns[i].mapping_id,
                        })

                        //columns.push(`${step.tableFromColumns[i].column_name} as ${step.tableFromColumns[i].display_name.replace(/\s/g, '_').toLowerCase()}`);
                    }   

                    this.data.step.tableToColumns = columns;
                    emitData('emit-data', this.data);
                })
                .catch(err => {
                    console.error(err);
                })

        }
    }
}
</script>
    