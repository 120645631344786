<template>
<v-container>
   <v-form>
      <v-row>
         <v-col cols="6">
            <v-text-field data-cy="transformationCalculateTableTo" color="primary" :hint="errors.tableTo?.join(' - ')" outlined dense v-model="data.step.tableTo" label="Step Name" :error="(errors?.tableTo)?true:false" @input="onChange(data.step, 'tableTo')" persistent-hint></v-text-field>
         </v-col>
         <v-col cols="6">
            <v-text-field data-cy="transformationCalculateNewColumnName" color="primary" outlined dense v-model="data.step.newColumnName" label="New Column Name" :error="(errors?.newColumnName)?true:false" @input="onChange(data.step, 'newColumnName')" :hint="errors.newColumnName?.join(' - ')" persistent-hint></v-text-field>
         </v-col>
         <v-col cols="6">
            <v-select @focus="refreshCols()" label="Columns" dense outlined color="accent" @input="onChange(data.step, 'descriptiveColumns')" persistent-hint :items="data.step.tableFromColumns" item-text="pretty_name" multiple small-chips v-model="data.step.descriptiveColumns" return-object></v-select>
         </v-col>
         <v-col cols="12">
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
            </v-card-actions>
         </v-col>
      </v-row>

   </v-form>
</v-container>
</template>

<script>
import {
   emitData
} from '@/utils/eventBus'
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'

export default {
   name: 'GroupNode',
   mixins: [formRules, nodeCode],
   props: {
      node: {
         type: Object,
         default: () => {
            return {}
         }
      },
   },

   data() {

      return {

         data: {
            step: {},
            rules: {}
         },

         errors: {},

      }

   },

   created() {

      this.processParentData()
      this.data.step.type = 'clustering'
   },

   computed: {

      disabledRules() {
         let isDisabled = false
         if (!this.data.step.tableTo || !this.data.step.newColumnName || (this.data.step.categoricalColumns.length + this.data.step.continuousColumns.length <= 1)) {
            isDisabled = true
         }
         return isDisabled;
      },

   },
   methods: {

      emitDataAction() {

         String.prototype.initCap = function () {
            return this.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m) {
               return m.toUpperCase();
            });
         };

         this.data.step.tableToColumns = structuredClone(this.data.step.tableFromColumns)

         this.data.step.outputColumn = {
            column_name: this.data.step.newColumnName.replace(/\s/g, '_').toLowerCase(),
            data_type_id: 2,
            pretty_name: this.data.step.newColumnName.replaceAll('_', ' ').initCap()
         }
         this.data.step.tableToColumns.push(this.data.step.outputColumn)

         emitData('emit-data', this.data);

      }
   }
}
</script>
