<template>
  <v-container>
      <v-row>
        <v-col cols="6">
            <v-text-field color="primary" outlined dense v-model="data.step.tableTo" label="Step Name" @input="onChange(data.step, 'tableTo')" :error="(errors?.tableTo)?true:false" :hint="errors.tableTo?.join(' - ')" persistent-hint></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select label="Postcode Column" dense outlined color="accent" :items="data.step.tableFromCategoricalColumns" item-text="pretty_name" item-value="column_name" multiple small-chips v-model="data.step.postcodeColumn" @input="onChange(data.step, 'postcodeColumn')" :error="(errors?.postcodeColumn)?true:false" :hint="errors.postcodeColumn?.join(' - ')" persistent-hint></v-select>
        </v-col>
        <v-col cols="12">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="emitDataAction" color="primary" :disabled="disabledRules">Save</v-btn>
            </v-card-actions>
        </v-col>
      </v-row>
  </v-container>
</template>
  
<script>
import { emitData } from '@/utils/eventBus'
const {v4: uuidv4} = require('uuid');
import formRules from '@/utils/validation/formRules'

export default {
  name: 'postcodeConversionNode',
  mixins: [formRules],
  props: {
    node: { type: Object, default:() => { return {} }}
  },
  data() {
    return {
      errors:{},
      data: {
        step: {
          type: 'enrichment',
          modelType: 'postcodeConversion',
          tableFrom: null,
          tableFromDatabaseName: null,
          tableFromColumns: null,
          tableFromNumericalColumns: [],
          tableFromCategoricalColumns: [],
          tableFromDateColumns: [],

          tableTo: null,
          tableToDatabaseName: uuidv4().replace(/-/g, '_').toLowerCase(), 
          tableToColumns: null,

          postcodeColumn: null,
        },
        rules: {
            tableTo: {rule : 'minText'},
            postcodeColumn: {rule : 'notEmpty'},

        },
        node: this.node,

      },
    }
  },

  created() {

    if (this.node.parentId !== -1) {

        this.data.step.tableFrom = this.node.parentData.tableTo;
        this.data.step.tableFromDatabaseName = this.node.parentData.tableToDatabaseName;

        this.data.step.tableFromColumns = structuredClone(this.node.parentData.tableToColumns);
        this.data.step.tableFromNumericalColumns = this.node.parentData.tableToColumns.filter(item => item.data_type_id === 1)
        this.data.step.tableFromCategoricalColumns = this.node.parentData.tableToColumns.filter(item => item.data_type_id === 2)
        this.data.step.tableFromDateColumns = this.node.parentData.tableToColumns.filter(item => item.data_type_id === 3)
                
    }

    if (this.node?.data?.step??null !== null) {
      this.data.step = this.node.data.step
    }

  },
  computed: {
        disabledRules(){
            let isDisabled = false;
            for (const key in this.errors) {
                if (this.errors[key] != null) {
                    isDisabled = true
                }
            }
            if  (
                    !this.data.step.tableTo ||
                    !this.data.step.postcodeColumn
                ) {
                    isDisabled = true
                }
            return isDisabled;
        },
    },
    methods: {

      onChange(location, fieldName) {
        this.errors = this.testForErrors(location, this.data.rules, fieldName, this.errors)
      },

       emitDataAction() {

        // String.prototype.initCap = function () {
        //   return this.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m) {
        //     return m.toUpperCase();
        //   });
        // };

        this.data.step.tableToColumns = structuredClone(this.data.step.tableFromColumns);

        let obj = {
            data_type_id: 1, // get from tag sele,   
            column_name: 'longitude', // string replace,
            pretty_name: 'Longitude'};

        this.data.step.tableToColumns.push(obj);

        obj = {
            data_type_id: 1, // get from tag sele,   
            column_name: 'latitude', // string replace,
            pretty_name: 'Latitude'};

        this.data.step.tableToColumns.push(obj);

        emitData('emit-data', this.data);

      }

  }
}
</script>