<template>
  <v-snackbar data-cy="snackBar" :color="chosenColor" transition="slide-y-reverse-transition" timeout="3000" v-model="snackbar" absolute bottom center class="mb-6">
    <v-row class="justify-space-between align-center">
      <v-col cols="9">
        <strong>
          {{ snackbarMessage }}
        </strong>
      </v-col>
      <v-col cols="3" class="px-0 text-right">
        <v-btn data-cy="snackBarCloseButton" small color="white" class="primary--text font-weight-bold" @click="snackbar = false">Close</v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>

import EventBus, { ACTIONS } from '@/utils/eventBus/';
import { userStore } from '@/stores/UserStore';

export default {
  name:'SnackBar',
  setup() {
    const user = userStore()
    return {
        user
    }
  },
  data () { return {
      snackbar: false,
      snackbarMessage: '',
      colorTypes: {
        'success':'primary',
        'warning':'warning',
        'error':'error'
      },
      chosenColor:'primary'
    }},
  mounted() {
    EventBus.$on(ACTIONS.SNACKBAR, (data) => {
      this.snackbarMessage = data.message;
      this.chosenColor = this.colorTypes[data.type];
      this.snackbar = true;
      // this.user.addNotification(data.message, data.type)
    });
  },
};
</script>

<style>
.v-sheet.v-snack__wrapper{

  max-width: none !important;
}

</style>