<template>
	<v-container>
		<form>
			<v-row>
				<v-col cols="12">
					<v-text-field
						color="primary"
						outlined
						dense
						v-model="data.step.tableTo"
						label="Step Name"
						:hint="errors.tableTo?.join(' - ')"
						:error="errors?.tableTo ? true : false"
						@input="onChange(data.step, 'tableTo')"
						persistent-hint
					></v-text-field>
				</v-col>
				<v-col cols="12" ref="a">
					<v-row
						
					>
						<v-col cols="6" class="mb-n5">
							<v-text-field v-for="(column, index) in data.step.tableFromColumns"
						:key="index"
								outlined
								dense
								label="Original Name"
								readonly
								v-model="column.pretty_name"
							></v-text-field>
						</v-col>
						<v-col cols="6" class="mb-n5">
							<v-text-field
                     v-for="(column, index) in data.step.tableToColumns"
						:key="index"
								outlined
								dense
								label="New Name"
								v-model="data.step.tableToColumns[index].pretty_name"
							></v-text-field>
						</v-col>
					</v-row>
				</v-col>

				<v-col cols="12">
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							:data-cy="`saveButton${node.demoNodeIndex}`"
							@click="emitDataAction()"
							color="primary"
						>
							{{
								data.node.data.status === 'unconfigured'
									? 'Save'
									: 'Update'
							}}
						</v-btn>
					</v-card-actions>
				</v-col>
			</v-row>
		</form>
	</v-container>
</template>

<script>
import {emitData} from '@/utils/eventBus';
import formRules from '@/utils/validation/formRules';
import nodeCode from '@/utils/pipelines/nodeCode';

export default {
	name: 'RemoveColumnsNode',
	mixins: [formRules, nodeCode],
	props: {
		node: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data() {
		return {
			errors: {},
			data: {
				step: {},
				rules: {
					tableTo: {
						rule: 'notEmpty',
					},
					tableToColumns: {
						rule: 'notEmptyArray',
					},
				},
			},
		};
	},
	created() {
		this.processParentData();
		this.data.step.type = 'rename';

		if (this.data.step.tableToColumns.length === 0) {
			this.data.step.tableToColumns = structuredClone(
				this.data.step.tableFromColumns
			);
		} else if (
			this.data.step.tableFromColumns.length !==
			this.data.step.tableToColumns.length
		) {
			// Find columns that are missing from the tableToColumns
			const cloneOne = structuredClone(this.data.step.tableFromColumns);
			const cloneTwo = structuredClone(this.data.step.tableToColumns);

			//find the columns that are in cloneTwo but not in cloneOne
			const missingColumns = cloneTwo.filter(
				(x) => !cloneOne.some((y) => y.column_name === x.column_name)
			);

			// add the missing columns to the tableToColumns
			for (let index = 0; index < missingColumns.length; index++) {
				// Needs to update the new names array with the value in the correct array position
				let i = cloneTwo.indexOf(missingColumns[index]);

				//this.data.step.tableToColumns.splice(i, 0, missingColumns[index])
				this.data.step.tableFromColumns.splice(i, 0, missingColumns[index]);
				//this.data.step.tableToColumns.push(missingColumns[index])
				//this.data.step.tableFromColumns.push(missingColumns[index])
				//this.data.step.tableFromColumns.push(missingColumns[index])
			}
			//this.data.step.tableToColumns = this.data.step.tableToColumns.concat(missingColumns)
		}
	},
	mounted() {
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.5,
		};

		const observer = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				this.elementVisible();
			} else {
				this.elementNotVisible();
			}
		}, options);

      observer.observe(this.$refs.a);
      this.data.step.type = 'rename';
	},
	computed: {
		disabledRules() {
			let isDisabled = false;
			for (const key in this.errors) {
				if (this.errors[key] != null) {
					isDisabled = true;
				}
			}
			if (
				!this.data.step.tableTo ||
				this.data.step.tableToColumns.length < 1
			) {
				isDisabled = true;
			}
			return isDisabled;
		},
	},
   methods: {
      arraysMatch(arr1, arr2) {
         if (arr1.length !== arr2.length) {
        return false;
    }

    const map = new Map();
    arr1.forEach(obj => map.set(obj.mapping_id, obj));

    for (let obj of arr2) {
        if (!map.has(obj.mapping_id) || JSON.stringify(map.get(obj.mapping_id)) !== JSON.stringify(obj)) {
            return false;
        }
    }

    return true;
},

		async elementVisible() {
         
         if(this.data.step.type === null){
            this.data.step.type = 'rename'
         }
         await this.processParentData();
         
         const tableFromIds = this.data.step.tableFromColumns.map(x => x.mapping_id);
         const tableToIds = this.data.step.tableToColumns.map(x => x.mapping_id);
         const idsMissingFrom = tableToIds.filter(x => !tableFromIds.includes(x));
         //const idsMissingTo = tableFromIds.filter(x => !tableToIds.includes(x));
         
         // TODO input the missing id into the correct array
         /*if(idsMissingTo.length > 0){
            for (let index = 0; index < idsMissingTo.length; index++) {
               const element = this.data.step.tableFromColumns.find(x => x.mapping_id === idsMissingTo[index]);
               console.log('spliceing', element)
               const i = this.data.step.tableFromColumns.indexOf(element)
               this.data.step.tableToColumns.splice(i, 1)
            }
         }*/
         if(idsMissingFrom.length > 0){
            for (let index = 0; index < idsMissingFrom.length; index++) {
               //remove from the tableToColumns
               const element = this.data.step.tableToColumns.find(x => x.mapping_id === idsMissingFrom[index]);
               
               this.data.step.tableFromColumns.push(element)
            }
         }
         /*
         if (!this.arraysMatch(this.data.step.tableFromColumns, this.data.step.tableToColumns)) {
            console.log(this.data.step.tableFromColumns.length, this.data.step.tableToColumns.length)
            if(this.data.step.tableToColumns.length === 0){
               this.data.step.tableToColumns = structuredClone(this.data.step.tableFromColumns)
               this.data.step.tableFromColumns = structuredClone(this.data.step.tableToColumns)
            } else {
               this.data.step.tableFromColumns = structuredClone(this.data.step.tableToColumns)
               this.data.step.tableToColumns = structuredClone(this.data.step.tableToColumns)
            }
            //console.log('no matchy', this.data.step.tableFromColumns, this.data.step.tableToColumns)
            

            //this.data.step.tableToColumns = structuredClone(this.data.step.tableFromColumns)
            //this.data.step.tableFromColumns = structuredClone(this.data.step.tableToColumns)
         }*/

		},
		emitDataAction() {
			// remove columns that don't have the correct column name
			//const validColumns = this.data.step.tableFromColumns.map(x => x.column_name)

			//const newNames = this.data.step.tableToColumns.map(x => x.new_name)

			//this.data.step.tableToColumns = this.data.step.tableToColumns.filter(x => validColumns.includes(x.column_name))
			for (
				let index = 0;
				index < this.data.step.tableToColumns.length;
				index++
			) {
				this.data.step.tableToColumns[index].column_name =
					this.data.step.tableToColumns[index].pretty_name
						.replace(/\s/g, '_')
						.toLowerCase();
			}

			emitData('emit-data', this.data);
		},
	},
};
</script>
