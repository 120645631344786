import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@fortawesome/fontawesome-pro/css/all.css';
import colors from 'vuetify/lib/util/colors'


Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
        customProperties: true,
        fontFamily: 'Quicksand, sans-serif',
       },
    light: true,
    themes: {
      // Green and Gold
      light: {
        options: {
            customProperties: true,
            fontFamily: 'Quicksand, sans-serif',
           },
        accent: '#ECEFF1',
        secondary: '#CD9900',
        primary: '#004D40',
        positive: colors.green.lighten1,
        negative: colors.red.lighten1
      }
    },
  },
})

export default vuetify
