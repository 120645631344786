<template>
<v-container>

    <v-row>
        <v-col cols="4">
            <v-text-field color="primary" outlined dense v-model="data.step.tableTo" :error="(errors?.tableTo)?true:false" label="Step Name" :hint="errors.tableTo?.join(' - ')" @input="onChange(data.step, 'tableTo')" @blur="onChange(data.step, 'tableTo')" persistent-hint></v-text-field>
        </v-col>

        <v-col cols="4">
            <v-text-field color="primary" outlined dense v-model="data.step.newColumnName" :error="(errors?.newColumnName)?true:false" label="Name of New Column" :hint="errors.newColumnName?.join(' - ')" @input="onChange(data.step, 'newColumnName')" persistent-hint></v-text-field>
        </v-col>

        <v-col cols="4">
            <v-select item-color="primary" color="primary" dense small-chips outlined :error="(errors?.newColumnDataType)?true:false" label="Data Type of New Column" v-model='data.step.newColumnDataType' :items="dataTypes" item-text="label" item-value="data_type" :hint="errors.newColumnDataType?.join(' - ')" @input="onChange(data.step, 'newColumnDataType')" persistent-hint></v-select>
        </v-col>

        <v-col cols="12" class="py-0">
            <v-card flat outlined class="border">
                <v-expansion-panels flat>
                    <v-expansion-panel v-for="(item, i) in data.step.tags" :key="i" class="pa-0 mx-0 px-0" flat>

                        <v-expansion-panel-header class="pl-4 pr-3 py-2" expand-icon="mdi-menu-down">
                            <v-card-title class="pa-0 text-body-1 font-weight-bold">
                                <v-btn v-show="data.step.tags.length > 1" min-width="15px" text min-height="20px" class="mr-3 pa-1" x-small color="primary" @click="removeTag(i)">
                                    <v-icon small class="pa-0 font-weight-bold">fa-thin fa-close</v-icon>
                                </v-btn>
                                {{data.step.tags[i].taggedValue}}
                            </v-card-title>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content class="px-0">

                            <v-card-text class="text-subtitle-1 pl-0 py-0 pb-2"><strong> When</strong></v-card-text>

                            <v-col cols="12" class="">

                                <!--<v-row class="py-0" v-for="(item, index) in data.step.filters" :key="index">-->
                                <v-row class="" v-for="(tagItem, index) in data.step.tags[i].filters" :key="index">

                                    <!-- column -->
                                    <v-col cols="3" class="py-0">
                                        <v-select @focus="refreshCols()" outlined dense return-object label="Column" v-model='data.step.tags[i].filters[index].column' :items="data.step.tableFromColumns" item-text="pretty_name" :hint="errors.column?.[index]?.join(' - ')" :error="(errors?.column?.[index])?true:false" @input="onChange(data.step.tags[i].filters[index], 'column'); data.step.tags[i].filters[index].value = null" persistent-hint></v-select>
                                    </v-col>

                                    <!-- word -->
                                    <v-col cols="2" class="py-0">
                                        <v-select outlined dense return-object label="Filter" v-model='data.step.tags[i].filters[index].operator' :items="['Equal To', 'Not Equal To', 'Less Than', 'Greater Than', 'Is Null', 'Is Not Null']" v-show="data.step.tags[i].filters[index].column.data_type_id === 1 || data.step.tags[i].filters[index].column.data_type_id === null" :hint="errors.operator?.[index]?.join(' - ')" :error="(errors?.operator?.[index])?true:false" @input="onChange(data.step.tags[i].filters[index], 'operator')" persistent-hint></v-select>
                                        <v-select outlined dense return-object label="Filter" v-model='data.step.tags[i].filters[index].operator' :items="['Equal To', 'Not Equal To', 'Contains', 'Does Not Contain', 'Is Null', 'Is Not Null']" v-show="data.step.tags[i].filters[index].column.data_type_id === 2" :hint="errors.operator?.[index]?.join(' - ')" :error="(errors?.operator?.[index])?true:false" @input="onChange(data.step.tags[i].filters[index], 'operator')" persistent-hint></v-select>
                                        <v-select outlined dense return-object label="Filter" v-model='data.step.tags[i].filters[index].operator' :items="['On', 'Before', 'After', 'Is Null', 'Is Not Null']" v-show="data.step.tags[i].filters[index].column.data_type_id === 3" :hint="errors.operator?.[index]?.join(' - ')" :error="(errors?.operator?.[index])?true:false" @input="onChange(data.step.tags[i].filters[index], 'operator')" persistent-hint></v-select>
                                        <v-select outlined dense return-object label="Filter" v-model='data.step.tags[i].filters[index].operator' :items="['Is', 'Is Not', 'Is Null', 'Is Not Null']" v-show="data.step.tags[i].filters[index].column.data_type_id === 4" :hint="errors.operator?.[index]?.join(' - ')" :error="(errors?.operator?.[index])?true:false" @input="onChange(data.step.tags[i].filters[index], 'operator')" persistent-hint></v-select>
                                    </v-col>

                                    <!-- value - value -->
                                    <div cols="3" v-if="data.step.tags[i].filters[index].type === 'value' && data.step.tags[i].filters[index].operator !== 'Is Null' && data.step.tags[i].filters[index].operator !== 'Is Not Null'">
                                        <v-col class="py-0">
                                            <!-- date -->
                                            <div v-if="data.step.tags[i].filters[index].column.data_type_id === 3">
                                                <v-menu @input="formatDate()" ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="date" lazy transition="scale-transition" offset-y full-width min-width="290px">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field outlined dense :value="changeDisplayDateFormat(i, index)" label="Date" readonly v-on="on"></v-text-field>

                                                    </template>
                                                    <v-date-picker v-model='data.step.tags[i].filters[index].value' no-title scrollable @input="menu = false"></v-date-picker>
                                                </v-menu>
                                            </div>
                                            <!-- text -->
                                            <div v-if="data.step.tags[i].filters[index].column.data_type_id !== 3">
                                                <v-text-field outlined dense label="Value" v-model='data.step.tags[i].filters[index].value' :error="(errors?.value?.[index])?true:false" :hint="errors.value?.[index]?.join(' - ')" @input="onChange(data.step.tags[i].filters[index], 'value')" persistent-hint></v-text-field>
                                            </div>
                                        </v-col>
                                    </div>

                                    <!-- value - column -->
                                    <div cols="3" v-if="data.step.tags[i].filters[index].type === 'column' && data.step.tags[i].filters[index].operator !== 'Is Null' && data.step.tags[i].filters[index].operator !== 'Is Not Null'" >
                                        <v-col class="py-0">
                                            <v-select @focus="refreshCols()" outlined dense return-object label="Column" v-model='data.step.tags[i].filters[index].value' :items="data.step.tableFromColumns.filter(column => column.data_type_id === data.step.tags[i].filters[index].column.data_type_id)" item-text="pretty_name" :hint="errors.column?.[index]?.join(' - ')" :error="(errors?.column?.[index])?true:false" @input="onChange(data.step.tags[i].filters[index], 'column')" persistent-hint></v-select>
                                        </v-col>
                                    </div>

                                    <!-- switch -->
                                    <v-col cols="3" class="mt-n6">
                                        <v-spacer></v-spacer>
                                        <v-switch v-model="data.step.tags[i].filters[index].type" hide-details true-value="value" false-value="column" :label="`${data.step.tags[i].filters[index].type}`" @change="data.step.tags[i].filters[index].value = null"></v-switch>
                                    </v-col>

                                    <!-- remove -->
                                    <v-col cols="1" class="">
                                        <v-btn v-if="data.step.tags[i].filters?.length > 1" color="primary" @click="removeFilter(i, index)" size="x-small">
                                            <v-icon>fa-thin fa-minus</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-row class="pt-10">

                                <v-col cols="4" class="d-flex justify-start">
                                    <!-- v-if="index === data.step.tags[i].filters.length-1" -->
                                    <v-btn small class="mr-2" color="primary" @click="addFilter('And', i)">
                                        <v-icon left>
                                            fa-thin fa-ampersand
                                        </v-icon>And
                                    </v-btn>
                                    <v-btn small class="" color="primary" @click="addFilter('Or', i)">
                                        <v-icon left>
                                            fa-thin fa-arrow-down-arrow-up
                                        </v-icon>Or
                                    </v-btn>
                                </v-col>

                                <v-col cols="6" class="mt-n4 d-flex flex-start">
                                    <v-card-text class="text-subtitle-1">
                                        <strong>Then Set The Value of column '{{data.step.newColumnName}}' to: </strong>
                                    </v-card-text>
                                    <v-text-field outlined color="primary" :error="(errors?.taggedValue?.[i])?true:false" dense label="Tag" v-model='data.step.tags[i].taggedValue' :hint="errors.taggedValue?.[i]?.join(' - ')" @input="onChange(data.step.tags[i], 'taggedValue', i)" persistent-hint></v-text-field>
                                </v-col>

                                <!--<v-col cols="12" class="mb-n1 py-0 d-flex justify-start" v-if="index != data.step.tags[i].filters.length-1">
                           <v-card-text v-if="data.step.andOr.length >= 1" class="text-overline pa-0 pl-1"><strong>{{data.step.andOr[index]}}</strong></v-card-text>
                        </v-col>-->

                            </v-row>

                        </v-expansion-panel-content>

                        <v-divider v-if="data.step.tags.length > 1 && i < data.step.tags.length - 1"></v-divider>

                    </v-expansion-panel>

                </v-expansion-panels>
            </v-card>

            <v-btn small color="primary" class="mt-4 mb-0" @click="addTag()">
                <v-icon left>fa-thin fa-plus</v-icon>Add Another Tag
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
            </v-card-actions>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import {
    emitData
} from '@/utils/eventBus'
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'
//import DatePickerModal from '@/components/analytics/DatePickerModal.vue';
import moment from "moment";

export default {
    name: 'TagNode',
    mixins: [formRules, nodeCode],
    components: {
        //DatePickerModal
    },
    props: {
        node: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            textKey: 0,
            errors: {},
            dataTypes: [{
                    label: 'Number',
                    data_type: 1,
                    type: 'number'
                },
                {
                    label: 'Text',
                    data_type: 2,
                    type: 'text'
                },
                {
                    label: 'Date/Datetime',
                    data_type: 3,
                    type: 'date'
                },
                {
                    label: 'Boolean',
                    data_type: 4,
                    type: 'boolean'
                },
                {
                    label: 'Coordinates',
                    data_type: 5,
                    type: 'geographic'
                }
            ],
            data: {
                step: null,
                rules: {
                    tableTo: {
                        rule: 'notEmpty'
                    },
                    newColumnName: {
                        rule: 'notEmpty'
                    },
                    newColumnDataType: {
                        rule: 'notNull'
                    },
                    value: {
                        rule: 'onlyNumbers'
                    },
                    method: {
                        rule: 'notNull'
                    },
                    type: {
                        rule: 'notNull'
                    },
                    column: {
                        rule: 'notNull'
                    },
                    taggedValue: {
                        rule: 'notNull'
                    },
                }
            }
        }

    },
    created() {

        this.processParentData()

        this.data.step.type = 'tag'
        this.data.step.newColumnDataType = 2

    },

    computed: {

        disabledRules() {

            let isDisabled = false;
            /*
            for (const key in this.errors) {
                if (this.errors[key].some(item => item !== null)) {
                    isDisabled = true
                }
            }
            if (
                !this.data.step.tableTo ||
                !this.data.step.newColumnName ||
                !this.data.step.newColumnDataType ||
                (this.data.step.tags && this.data.step.tags.length < 1) ||
                //looking for all nested tags errors
                this.data.step.tags.some(item => item.taggedValue == null ||
                    item.filters.some(filterItem => filterItem.column == null ||
                        filterItem.operator == null ||
                        filterItem.type == null ||
                        filterItem.value == null
                    )
                )
            ) {
                isDisabled = true
            }
            */

            return isDisabled;

        },

        allowedDataTypes() {

            if (this.data.step.tableFromColumns && this.data.step.newColumnDataType) {
                return this.data.step.tableFromColumns.filter(item => item.data_type_id === this.data.step.newColumnDataType)
            } else {
                return []
            }

        },
    },

    methods: {
        formatDate() {

            for (var i = 0; i < this.data.step.tags.length; i++) {
                for (var a = 0; a < this.data.step.tags[i].filters.length; a++) {
                    if (this.data.step.tags[i].filters[a].column.data_type_id === 3 && this.data.step.tags[i].filters[a].value.indexOf('/') !== -1) {
                        this.data.step.tags[i].filters[a].value = moment(this.data.step.tags[i].filters[a].value, 'DD/MM/YYYY').format('YYYY-MM-DD')
                    }
                }
            }
        },
        changeDisplayDateFormat(i, index) {

            let x;
            if (this.data.step.tags[i].filters[index].value === null) {
                x = null
            } else if (this.data.step.tags[i].filters[index].value.indexOf('/') !== -1) {
                x = this.data.step.tags[i].filters[index].value
              //this.data.step.filters[index].value = moment(this.data.step.filters[index].value, 'DD/MM/YYYY').format('YYYY-MM-DD')
            } else if (this.data.step.tags[i].filters[index].value.indexOf('-') !== -1) {
                //this.data.step.filters[index].value = moment(this.data.step.filters[index].value, 'YYYY-MM-DD').format('DD/MM/YYYY')
                x = moment(this.data.step.tags[i].filters[index].value, 'YYYY-MM-DD').format('DD/MM/YYYY')
            } else {
                x = this.data.step.tags[i].filters[index].value
            }

            return x
        },
        /*
       changeDisplayDateFormat(i, index){
      return moment(new Date(this.data.step.tags[i].filters[index].value)).format('DD/MM/YYYY');
      },*/

        addFilter(item, i) {

            this.data.step.tags[i].filters.push({
                column: {
                    data_type_id: null
                },
                operator: null,
                type: 'value',
                value: null
            });

            this.data.step.tags[i].andOr.push(item);

        },

        addTag() {

            this.data.step.numTags = this.data.step.numTags + 1;
            this.data.step.tags.push({
                filters: [{
                    column: {
                        data_type_id: null
                    },
                    operator: null,
                    type: 'value',
                    value: null
                }],
                andOr: [],
                taggedValue: `Tag ${this.data.step.numTags}`,
            });

        },

        removeFilter(i, index) {

            this.data.step.tags[i].filters.splice(index, 1)
            this.data.step.tags[i].andOr.splice(index - 1, 1)

        },

        removeTag(i) {

            this.data.step.tags.splice(i, 1)

        },

        emitDataAction() {

            this.data.step.tableToColumns = structuredClone(this.data.step.tableFromColumns);

            let obj = {
                data_type_id: this.data.step.newColumnDataType, // get from tag sele,   
                column_name: this.data.step.newColumnName.replace(/\s/g, '_').toLowerCase(), // string replace,
                pretty_name: this.data.step.newColumnName
            };

            this.data.step.tableToColumns.push(obj);

            for (var i = 0; i < this.data.step.tags.length; i++) {
                for (var a = 0; a < this.data.step.tags[i].filters.length; a++) {
                    if (this.data.step.tags[i].filters[a].column.data_type_id === 3) {
                        this.data.step.tags[i].filters[a].value = moment(new Date(this.data.step.tags[i].filters[a].value)).format('DD/MM/YYYY')
                    }
                }
            }

            emitData('emit-data', this.data);
        }
    }
}
</script>
