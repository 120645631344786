<template>
<v-container>
    <form>

        <v-row>
            <v-col cols="6" class="pt-0">
                <v-text-field data-cy="transformationCalculateTableTo" color="primary" :hint="errors.tableTo?.join(' - ')" outlined dense v-model="data.step.tableTo" label="Step Name" :error="(errors?.tableTo)?true:false" @input="onChange(data.step, 'tableTo')" persistent-hint></v-text-field>
            </v-col>

            <v-col cols="6" class="pt-0">
                <v-text-field data-cy="transformationCalculateNewColumnName" color="primary" outlined dense v-model="data.step.newColumnName" label="New Column Name" :error="(errors?.newColumnName)?true:false" @input="onChange(data.step, 'newColumnName')" :hint="errors.newColumnName?.join(' - ')" persistent-hint></v-text-field>
            </v-col>

            <v-col cols="12" class="py-01">
                <v-textarea @click:clear="clearTextArea()" rows="2" auto-grow outlined label="Formula" v-model="data.step.frontendFormula" :error="(errors?.frontendFormula)?true:false" @input="onChange(data.step, 'frontendFormula')" :hint="errors.frontendFormula?.join(' - ')" readonly clearable persistent-hint>
                </v-textarea>
            </v-col>

            <v-row class="pl-3 py-2">
                <v-col cols="9" class="pt-0">
                    <v-card data-cy="categoriesContainer" outlined dense :style="'border: 1px solid'">
                        <v-chip small v-for="(item, index) in data.step.tableFromNumericalColumns" :key="index" class="ma-1" @click="addToFormula(`${item.pretty_name}`, `${item.column_name}`)">
                            {{item.pretty_name}}
                        </v-chip>
                    </v-card>
                </v-col>

                <v-col cols="3" class="pt-0">
                    <v-row class="pl-0 pr-8">
                        <v-col cols="4" class="">
                            <v-btn color="primary" @click="addToFormula(' + ', ' + ')">
                                <v-icon>fa-thin fa-plus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="4" class="">
                            <v-btn color="primary" @click="addToFormula(' - ', ' - ')">
                                <v-icon>fa-thin fa-minus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="4" class="">
                            <v-btn color="primary" @click="addToFormula(' x ', ' * ')">
                                <v-icon>fa-thin fa-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="pl-0 pr-8">
                        <v-col cols="4" class="">
                            <v-btn color="primary" @click="addToFormula(' ÷ ', ' / ')">
                                <v-icon>fa-thin fa-divide</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="4" class="">
                            <v-btn color="primary" @click="addToFormula(' (', ' (')">
                                <v-icon>fa-thin fa-bracket-round</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="4" class="">
                            <v-btn color="primary" @click="addToFormula(') ', ') ')">
                                <v-icon>fa-thin fa-bracket-round-right</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
                </v-card-actions>
            </v-col>

            </v-row>

            <!--
            <v-col cols="10" class="pt-3 pb-0">
                <v-select item-color="primary" color="primary" dense small-chips outlined label="Select a Column to Include" :items="data.step.tableFromNumericalColumns" item-text="pretty_name" item-value="column_name" v-model="data.step.columnToAdd" :error="(errors?.columnToAdd)?true:false" @input="onChange(data.step, 'columnToAdd')" :hint="errors.columnToAdd?.join(' - ')" persistent-hint></v-select>
            </v-col>
            <v-col cols="2" class="pt-3 pb-0">
                <v-btn color="primary" @click="addToFormula(' ' + data.step.columnToAdd)" block>
                    <v-icon left>fa-thin fa-plus</v-icon>Add
                </v-btn>
            </v-col>
            <v-col cols="12" class="py-0">
                <v-card-actions class="py-0 pr-1">
                    <v-spacer></v-spacer>
                    <v-btn v-if="data.node.data.status === 'unconfigured'" @click="emitDataAction" :disabled="disabledRules" color="primary">Save</v-btn>
                    <v-btn v-if="data.node.data.status !== 'unconfigured'" @click="emitDataAction" :disabled="disabledRules" color="primary">Update</v-btn>
                </v-card-actions>
            </v-col>
            -->
        </v-row>
    </form>
</v-container>
</template>

<script>
import {
    emitData
} from '@/utils/eventBus'
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'

export default {

    name: 'CalculateNode',

    mixins: [formRules, nodeCode],

    props: {
        node: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data() {
        return {
            errors: {},
            data: {
                step: null,
                rules: {
                    tableTo: {
                        rule: 'notEmpty'
                    },
                    newColumnName: {
                        rule: 'notEmpty'
                    },
                    frontendFormula: {
                        rule: 'notEmpty'
                    },
                    tableFromColumns: {
                        rule: 'notEmpty'
                    }
                }
            }
        }
    },

    created() {

        this.processParentData()
        this.data.step.type = 'calculate'
        this.data.step.newColumnDataType = 1

    },

    computed: {

        disabledRules() {
            let isDisabled = false;
            for (const key in this.errors) {
                if (this.errors[key] != null) {
                    isDisabled = true
                }
            }
            if (
                !this.data.step.tableTo ||
                !this.data.step.newColumnName ||
                !this.data.step.frontendFormula ||
                !this.data.step.tableFromColumns
            ) {
                isDisabled = true
            }
            return isDisabled;
        },

    },

    methods: {

        clearTextArea() {

            this.data.step.frontendFormula = ''
            this.data.step.backendFormula = ''
            this.$forceUpdate();

            this.onChange(this.data.step, 'frontendFormula')

        },


        addToFormula(frontendItem, backendItem) {

            this.data.step.frontendFormula === null ? this.data.step.frontendFormula = '' : {}
            this.data.step.backendFormula === null ? this.data.step.backendFormula = '' : {}

            this.data.step.frontendFormula = this.data.step.frontendFormula + frontendItem;
            this.data.step.backendFormula = this.data.step.backendFormula + backendItem;
            this.$forceUpdate();

            this.onChange(this.data.step, 'frontendFormula')

        },

        emitDataAction() {

            this.data.step.tableToColumns = structuredClone(this.data.step.tableFromColumns);

            // add the new calculated column
            let obj = {
                data_type_id: this.data.step.newColumnDataType,
                column_name: this.data.step.newColumnName.replace(/\s/g, '_').toLowerCase(),
                pretty_name: this.data.step.newColumnName
            };

            this.data.step.tableToColumns.push(obj);

            emitData('emit-data', this.data);
        }
    }
}
</script>
