<template>
<v-container>
    <form>

        <v-row>

            <v-col cols="12">
                <v-text-field @focus="refreshCols()" color="primary" :hint="errors.tableTo?.join(' - ')" outlined dense v-model="data.step.tableTo" label="Step Name" @input="onChange(data.step, 'tableTo')" :error="(errors?.tableTo)?true:false" persistent-hint></v-text-field>
            </v-col>

            <v-col cols="12">
                <v-select @focus="refreshCols()" color="primary" item-color="primary" dense multiple small-chips outlined label="Aggregate By Columns" :items="data.step.tableFromColumns" item-text="pretty_name" item-value="column_name" v-model="data.step.aggBy" return-object @input="onChange(data.step, 'aggBy')" :error="(errors?.aggBy)?true:false" :hint="errors.aggBy?.join(' - ')" persistent-hint></v-select>
                <!--<v-select @focus="refreshCols()" color="primary" item-color="primary" dense multiple small-chips outlined label="Columns To Aggregate" :items="data.step.tableFromColumns" item-text="pretty_name" item-value="column_name" v-model="data.step.aggs" return-object @input="onChange(data.step, 'aggs')" :error="(errors?.aggs)?true:false" :hint="errors.aggs?.join(' - ')" persistent-hint></v-select>-->
                <v-card data-cy="categoriesContainer" outlined dense :style="'border: 1px solid'">
                    <v-chip small v-for="(item, index) in data.step.tableFromColumns" :key="index" class="ma-1" @focus="refreshCols()" @click="addAgg(item)">
                        {{item.pretty_name}}
                    </v-chip>
                </v-card>
            </v-col>
            <!-- For each column in p2 select an type sum/count/avg/max/min/distinct count with option to add additional agg for that column can't do same agg on same column twice -->
            <v-col cols="12" class="mt">
                <v-row v-for="(item, index) in data.step.aggs" :key="index">
                    <v-col cols="4" class="mb-n7">
                        <v-text-field readonly outlined dense label="Column" v-model='data.step.aggs[index].pretty_name'></v-text-field>
                    </v-col>

                    <v-col cols="6" class="mb-n7 d-flex justify-start">
                        <v-btn-toggle color="primary" class="mt-1" v-model="data.step.calcs[index]">
                            <v-btn value="COUNT" small>
                                Count
                            </v-btn>
                            <v-btn value="COUNT(DISTINCT" small>
                                Unique Count
                            </v-btn>
                            <v-btn value="SUM" small v-if="data.step.aggs[index].data_type_id === 1">
                                Sum
                            </v-btn>
                            <v-btn value="AVG" small v-if="data.step.aggs[index].data_type_id === 1">
                                Average
                            </v-btn>
                            <v-btn value="MIN" small v-if="data.step.aggs[index].data_type_id === 1">
                                Minimum
                            </v-btn>
                            <v-btn value="MAX" small v-if="data.step.aggs[index].data_type_id === 1">
                                Maximum
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>

                    <v-col cols="2" class="mb-n7 d-flex justify-end">
                        <v-btn small class="mt-1" color="primary" @click="removeAgg(index)">
                            <v-icon small>
                                fa-thin fa-minus
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12">
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
    </form>

</v-container>
</template>

<script>
import { emitData } from '@/utils/eventBus'
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'

export default {
    name: 'AggregateNode',
    mixins: [formRules, nodeCode],
    props: {
        node: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            errors: {},
            data: {
                step: {},
                rules: {
                    tableTo: {
                        rule: 'notEmpty'
                    },
                    aggBy: {
                        rule: 'notEmptyArray'
                    },
                    aggs: {
                        rule: 'notEmptyArray'
                    },
                },
            }
        }
    },
    created() {

        this.processParentData();
        this.data.step.type = 'aggregate'

    },

    computed: {

        disabledRules() {

            let isDisabled = false;
            for (const key in this.errors) {
                if (this.errors[key] != null) {
                    isDisabled = true
                }
            }
            if (
                !this.data.step.tableTo ||
                this.data.step.aggBy.length < 1 ||
                this.data.step.aggs.length < 1 ||
                this.data.step.calcs.length != this.data.step.aggs.length
            ) {
                isDisabled = true
            }
            return isDisabled;
        },

    },

    methods: {

        addAgg(item) {

            this.data.step.aggs.push(item);
            this.data.step.calcs.push('COUNT')

        },

        removeAgg(index) {

            this.data.step.aggs.splice(index, 1)
            this.data.step.calcs.splice(index, 1)

        },

        async emitDataAction() {

            // filter aggBy columns that don't have a valid column name 
            const validColumns = this.data.step.tableFromColumns.map(x => x.column_name)
            this.data.step.aggBy = this.data.step.aggBy.filter(x => validColumns.includes(x.column_name))
          
            this.data.step.tableToColumns = []

            for (let a = 0; a < this.data.step.aggBy.length; a++) {
                this.data.step.tableToColumns.push(this.data.step.aggBy[a]);
            }

            for (var i = 0; i < this.data.step.aggs.length; i++) {
                const aggregatedColumnInfo = {};
                aggregatedColumnInfo.column_name = this.data.step.calcs[i].replace(/\s/g, '_').replaceAll('(', '_').replaceAll(')', '_').toLowerCase() + '_of_' + this.data.step.aggs[i].column_name
                aggregatedColumnInfo.pretty_name = this.data.step.calcs[i].replaceAll('(', ' ').replaceAll(')', ' ') + ' Of ' + this.data.step.aggs[i].pretty_name
                aggregatedColumnInfo.data_type_id = 1
                this.data.step.tableToColumns.push(aggregatedColumnInfo)
            }

            emitData('emit-data', this.data);

        }
    }
}
</script>
