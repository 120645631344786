<template>
<v-container>
   <v-form>
      <v-row>
         <v-col cols="6">
            <v-text-field data-cy="transformationCalculateTableTo" color="primary" :hint="errors.tableTo?.join(' - ')" outlined dense v-model="data.step.tableTo" label="Step Name" :error="(errors?.tableTo)?true:false" @input="onChange(data.step, 'tableTo')" persistent-hint></v-text-field>
         </v-col>
         <v-col cols="6">
            <v-select @focus="refreshCols()" label="Columns" dense outlined color="accent" @input="onChange(data.step, 'descriptiveColumns')" persistent-hint :items="data.step.tableFromColumns" item-text="pretty_name" multiple small-chips v-model="data.step.descriptiveColumns" return-object></v-select>
         </v-col>
         <v-col cols="6">
            <v-select @focus="refreshCols()" label="Data Label (optional)" dense outlined color="primary" :items="data.step.tableFromCategoricalColumns" item-text="pretty_name" item-value="column_name" v-model="data.step.targetColumn" clearable return-object></v-select>
         </v-col>
         <v-col cols="12">
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
            </v-card-actions>
         </v-col>
      </v-row>
   </v-form>
</v-container>
</template>

<script>
import {
   emitData
} from '@/utils/eventBus'
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'

export default {
   name: 'ReduceComplexityNode',
   mixins: [formRules, nodeCode],
   props: {
      node: {
         type: Object,
         default: () => {
            return {}
         }
      },
   },

   data() {

      return {

         data: {
            step: {},
            rules: {}
         },
         errors: {},

      }

   },
   created() {

      this.processParentData();
      this.data.step.type = 'reduce complexity'

   },

   computed: {

      // TODO - this isn't being used
      categoricalItemsFilter() {
         return this.data.step.tableFromCategoricalColumns.filter(item => item.column_name != this.data.step.targetColumn)
      },

      disabledRules() {
         let isDisabled = false
         if (!this.data.step.tableTo || !this.data.step.newColumnName || (this.data.step.categoricalVars.length + this.data.step.continuousVars.length <= 1)) {
            isDisabled = true
         }
         return isDisabled;
      },

   },

   methods: {

      emitDataAction() {

         String.prototype.initCap = function () {
            return this.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m) {
               return m.toUpperCase();
            });
         };

         this.data.step.tableToColumns = [{
            column_name: 'dimension_one',
            data_type_id: 1,
            pretty_name: 'Dimension One'
         }, {
            column_name: 'dimension_two', 
            data_type_id: 1,
            pretty_name: 'Dimension Two'
         }]

         if (this.data.step.targetColumn != null) {
            this.data.step.tableToColumns.push(this.data.step.targetColumn)
         }
   
         emitData('emit-data', this.data);
         
      }
   }
}
</script>
