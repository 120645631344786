import Vue from 'vue'
import VueRouter from 'vue-router'
import {
userStore
} from '../stores/UserStore';

Vue.use(VueRouter)

const routes = [


   // Entry

   {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "about" */ '../views/entry/LoginView.vue'),
      meta: {
         pageTitle: "Login",
         subTitle: "Login",
         requiresAuth: false,

      }
   },

   {
      path: '/sign-up',
      name: 'Sign Up',
      component: () => import('../views/entry/SignUp.vue'),
      meta: {
         pageTitle: "Sign Up",
         subTitle: "Sign Up",
         requiresAuth: false
      },
   },
   
   {
      path: '/password-reset',
      name: 'Password Reset',
      component: () => import(/* webpackChunkName: "about" */ '../views/entry/ResetPasswordView.vue'),
      meta: {
         pageTitle: "Reset",
         subTitle: "Reset",
         requiresAuth: false,

      }
   },

   // Settings

   {
      path: '/settings',
      name: 'Settings',
      component: () => import('../views/settings/SettingsView.vue'),
      meta: {
         pageTitle: "Settings",
         subTitle: "Manage your settings",
         requiresAuth: true
      },
   },


   // Home

   {
      path: '/',
      name: 'Home',
      component: () => import('../views/home/HomeView.vue'),
      meta: {
         pageTitle: "Home",
         subTitle: "nameFromStore | Discovr Analytics",
         requiresAuth: true
      },
   },

   // Help Centre

   {
      path: '/help-centre',
      name: 'helpCentre',
      component: () => import('../views/help-centre/HelpCentre.vue'),
      meta: {
         pageTitle: "Help Centre",
         subTitle: "Search our extensive help centre for documentation and walkthrough videos.",
         requiresAuth: true
      },
   },


   // Connections

   {
      path: '/connections',
      name: 'connections',
      component: () => import('../views/connections/ConnectionsView.vue'),
      meta: {
         pageTitle: "Connections",
         subTitle: "Setup data connections for your data sources",
         requiresAuth: true
      },
   },

   {
      path: '/connections/upload/excel',
      name: 'addExcel',
      component: () => import('../views/connections/static/UploadExcel.vue'),
      meta: {
         pageTitle: "Spreadsheet",
         subTitle: "Excel Connection",
         requiresAuth: true
      },
   },

   {
      path: '/connections/upload/json',
      name: 'addJSON',
      component: () => import('../views/connections/static/UploadJSON.vue'),
      meta: {
         pageTitle: "JSON",
         subTitle: "JSON Connection",
         requiresAuth: true
      },
   },

   {
      path: '/connections/add',
      name: 'connections add pure api key',
      component: () => import('../views/connections/live/AddConnection.vue'),
      meta: {
         pageTitle: "Add Connection",
         subTitle: "Setup data connections for your data sources",
         requiresAuth: true
      },
   },

   {
      path: '/connections/manage',
      name: 'connections manage pure api key',
      component: () => import('../views/connections/live/ManageConnection.vue'),
      meta: {
         pageTitle: "Manage Connection",
         subTitle: "Manage a connection",
         requiresAuth: true
      },
   },

   { path: '/auth/callback:user(.*)', 
      component: () => import('../views/connections/live/AddConnection.vue'),
      props: {
         default: true,
      },
   },

   // Datasets

   {
      path: '/datasets',
      name: 'datasets',
      component: () => import('../views/datasets/DatasetsView.vue'),
      meta: {
         pageTitle: "Datasets",
         subTitle: "Manage your datasets",
         requiresAuth: true
      },
   },
   {
      path: '/dataset/preview',
      name: 'datasetPreview',
      component: () => import('../views/datasets/PreviewDataset.vue'),
      meta: {
         pageTitle: "Dataset Preview",
         subTitle: "Preview your dataset",
         requiresAuth: true
      },
   },


   // Pipelines

   {
      path: '/pipelines',
      name: 'PipelinesView',
      component: () => import('../views/pipelines/PipelinesView.vue'),
      meta: {
         pageTitle: "Pipelines",
         subTitle: "Setup data connections for your data sources",
         requiresAuth: true
      },
   },

   {
      path: '/pipelines/add',
      name: 'addPipeline',
      component: () => import('../views/pipelines/AddPipelineView.vue'),
      meta: {
         pageTitle: "Add Pipeline",
         subTitle: "Setup data connections for your data sources",
         requiresAuth: true
      },
   },

   {
      path: '/pipelines/edit',
      name: 'editPipeline',
      component: () => import('../views/pipelines/EditPipelineView.vue'),
      meta: {
         pageTitle: "Edit Pipeline",
         subTitle: "Edit An Existing Pipeline",
         requiresAuth: true
      },
   },


   // AI Hub

   {
      path: '/ai-hub',
      name: 'ai',
      component: () => import('../views/ai-hub/aiHubView.vue'),
      meta: {
         pageTitle: "Train & Predict",
         subTitle: "Create your predictions",
         requiresAuth: true
      },
   },   

   // Chat 

   {
      path: '/chat',
      name: 'Chat',
      component: () => import('../views/chat/ChatView.vue'),
      meta: {
         pageTitle: "Chat To Analyse",
         subTitle: "Open text AI Analysis",
         requiresAuth: true
      },
   },


   // Analytics 

   {
      path: '/analytics',
      name: 'analytics',
      component: () => import('../views/analytics/AnalyticsView.vue'),
      meta: {
         pageTitle: "Saved Dashboards",
         subTitle: "View your analytics",
         requiresAuth: true
      },
   },

   {
      path: '/analytics/add',
      name: 'addAnalytics',
      component: () => import('../views/analytics/AddAnalytics.vue'),
      meta: {
         pageTitle: "Data Analysis",
         subTitle: "Visualise your datasets",
         requiresAuth: true
      },
   },

   {
      path: '/analytics/add/category',
      name: 'categoryAnalysis',
      component: () => import('../views/analytics/AddCategoryAnalysis.vue'),
      meta: {
         pageTitle: "Categories",
         subTitle: "Analyse your data by category",
         requiresAuth: true
      },
   },  

   {
      path: '/analytics/add/custom',
      name: 'Custom Analytics',
      component: () => import('../views/analytics/AddCustomAnalysis2.vue'),
      meta: {
         pageTitle: "Custom",
         subTitle: "Request a custom analysis",
         requiresAuth: true
      },
   },

   {
      path: '/analytics/add/describe',
      name: 'Describe Analytics',
      component: () => import('../views/analytics/AddDescribeAnalysis.vue'),
      meta: {
         pageTitle: "Describe Analytics",
         subTitle: "Request a custom analysis",
         requiresAuth: true
      },
   },

   {
      path: '/analytics/add/forecast',
      name: 'Forecast Analytics',
      component: () => import('../views/analytics/AddForecastAnalysis.vue'),
      meta: {
         pageTitle: "Custom",
         subTitle: "Request a custom analysis",
         requiresAuth: true
      },
   },

   {
      path: '/analytics/add/geo',
      name: 'geoAnalysis',
      component: () => import('../views/analytics/AddGeoAnalysis.vue'),
      meta: {
         pageTitle: "Geo Location",
         subTitle: "Analyse your data by geo-spatial references",
         requiresAuth: true
      },
   },

   {
      path: '/analytics/add/kpi',
      name: 'KPI',
      component: () => import('../views/analytics/AddKPIAnalysis.vue'),
      meta: {
         pageTitle: "Custom",
         subTitle: "Request a custom analysis",
         requiresAuth: true
      },
   },

   {
      path: '/analytics/add/outlier',
      name: 'outlierAnalysis',
      component: () => import('../views/analytics/AddOutlierAnalysis.vue'),
      meta: {
         pageTitle: "Outliers",
         subTitle: "Find outliers within your data",
         requiresAuth: true
      },
   },

   {
      path: '/analytics/add/timeseries',
      name: 'timeseries',
      component: () => import('../views/analytics/AddTimeseries.vue'),
      meta: {
         pageTitle: "Timeseries Data",
         subTitle: "Analyse your data by time",
         requiresAuth: true
      },
   },


   // Insights

   {
      path: '/insights',
      name: 'insights',
      component: () => import('../views/insights/InsightsView.vue'),
      meta: {
         pageTitle: "Insights",
         subTitle: "Analyse your data using Insights",
         requiresAuth: true
      },
   },


   // Playbooks

   {
      path: '/playbooks',
      name: 'Playbooks Home',
      component: () => import('../views/playbooks/PlaybooksView.vue'),
      meta: {
         pageTitle: "Model Management",
         subTitle: "Your models",
         requiresAuth: true
      },
   },
   {
      path: '/playbooks/add',
      name: 'Add Playbooks',
      component: () => import('../views/playbooks/AddPlaybook.vue'),
      meta: {
         pageTitle: "Model Management",
         subTitle: "Your models",
         requiresAuth: true
      },
   },


   // Path Match

   { path: '/:pathMatch(.*)*',
      redirect: "/"
   }
   
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes
})

router.beforeEach((to, from, next) => {
   const user = userStore();
   if (to.query.embed === 'true') {
      next()
   }
   else if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!user.isLoggedIn) {
         next({
         path: '/login',
         query: { redirect: to.fullPath }
         })
      } else {
         next()
      }
   } else {
      next()
   }
   })


export default router
