<template>
<v-container>
   <v-form>
      <v-row>
         <v-col cols="6">
            <v-text-field data-cy="transformationCalculateTableTo" color="primary" :hint="errors.tableTo?.join(' - ')" outlined dense v-model="data.step.tableTo" label="Step Name" :error="(errors?.tableTo)?true:false" @input="onChange(data.step, 'tableTo')" persistent-hint></v-text-field>
         </v-col>

         <v-col cols="6">
            <v-select @focus="refreshCols()" label="Which column has missing values?" dense outlined color="accent" :items="data.step.tableFromColumns" item-text="pretty_name" item-value="column_name" v-model="data.step.targetColumn" return-object></v-select>
         </v-col>
         <v-col cols="6">
            <v-select @focus="refreshCols()" label="Columns" dense outlined color="accent" @input="onChange(data.step, 'descriptiveColumns')" persistent-hint :items="data.step.tableFromColumns" item-text="pretty_name" multiple small-chips v-model="data.step.descriptiveColumns" return-object></v-select>
         </v-col>

         <v-col cols="12">
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
            </v-card-actions>
         </v-col>
      </v-row>
   </v-form>
</v-container>
</template>

<script>
import {
   emitData
} from '@/utils/eventBus'
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'

export default {
   name: 'FillNaNode',
   mixins: [formRules, nodeCode],
   props: {
      node: {
         type: Object,
         default: () => {
            return {}
         }
      },
   },

   data() {

      return {

         data: {
            step: {},
            rules: {}
         },

         errors: {},

      }

   },

   created() {

      this.processParentData()
      this.data.step.type = 'fill na'

   },

   computed: {

      disabledRules() {
         let isDisabled = false
         if (!this.data.step.tableTo || !this.data.step.categoricalColumns[0] || this.data.step.trainPredictOnSameTable === null || (!this.data.step.trainPredictOnSameTable && !this.data.step.tableTrainDatasetId) || !this.data.step.targetColumn || (this.data.step.categoricalColumns.length < 1 && this.data.step.numericalColumns.length < 1)) {
            isDisabled = true
         }
         return isDisabled;
      },

   },
   methods: {

      emitDataAction() {

         this.data.step.type = (this.data.step.targetColumn.data_type_id === 1) ? 'fill numerical na' : 'fill categorical na'

         this.data.step.tableToColumns = structuredClone(this.data.step.tableFromColumns);

         emitData('emit-data', this.data);

      }
   }
}
</script>
