<template>
    <svg  viewBox="0 0 1080 1080" height="100%" width="100%" >
    <g id="g838">
     <g id="g830">
      <path id="path826" fill="none" class="st1" d="m392.3 541c0-22.2 11.1-41.9 28.1-53.6 15.8-11 25.3-28.9 25.3-48.2v-31.5c0-35.3-9.8-40.8-25.5-51.7-17.2-12.1-28.4-32.2-27.9-55 0.7-33.5 27.5-61.5 61-63.6 37.9-2.4 69.4 27.7 69.4 65.1 0 22.2-11.1 41.9-28.1 53.6-15.8 11-25.3 16.8-25.3 52.2v30.9c0 19.2 9.5 37.2 25.3 48.2 17 11.8 28.1 31.4 28.1 53.6s-11.1 41.9-28.1 53.6c-15.8 11-25.3 28.9-25.3 48.2v33.5c0 19.2 9.8 36.7 25.5 47.7 17.2 12.1 28.4 32.2 27.9 55-0.8 33.5-27.5 61.5-61 63.6-37.9 2.4-69.4-27.7-69.4-65.1 0-22.2 11.1-41.9 28.1-53.6 15.8-11 25.3-28.9 25.3-48.2v-32.9c0-19.2-9.5-37.2-25.3-48.2-17-11.7-28.1-31.4-28.1-53.6z">
        <animate id="project_anim2" attributeName="fill" fill="freeze"
        values="#ECEFF1; #004d40; #004d40; #ECEFF1; " keyTimes="0; 0.25; 0.70; 1" begin="project_anim1.end-1.2s" dur="2s"
        restart="whenNotActive" />
      </path>
      <rect class="shadow" x="34%" y="0%" width="15%" height="2%" fill="#ECEFF1">
        <animate attributeType="XML" attributeName="height"
          values="99%; 2%;"  begin="project_anim1.end-1.2s" dur="2s"
          restart="whenNotActive"/>
      </rect>
      <path id="path828" fill="none"  class="st0" d="m559.3 541c0-22.2 11.1-41.9 28.1-53.6 15.8-11 25.3-28.9 25.3-48.2v-31.5c0-35.3-9.8-40.8-25.5-51.7-17.2-12.1-28.4-32.2-27.9-55 0.7-33.5 27.5-61.5 61-63.6 37.9-2.4 69.4 27.7 69.4 65.1 0 22.2-11.1 41.9-28.1 53.6-15.8 11-25.3 16.8-25.3 52.2v30.9c0 19.2 9.5 37.2 25.3 48.2 17 11.8 28.1 31.4 28.1 53.6s-11.1 41.9-28.1 53.6c-15.8 11-25.3 28.9-25.3 48.2v33.5c0 19.2 9.8 36.7 25.5 47.7 17.2 12.1 28.4 32.2 27.9 55-0.8 33.5-27.5 61.5-61 63.6-37.9 2.4-69.4-27.7-69.4-65.1 0-22.2 11.1-41.9 28.1-53.6 15.8-11 25.3-28.9 25.3-48.2v-32.9c0-19.2-9.5-37.2-25.3-48.2-17-11.7-28.1-31.4-28.1-53.6z">
        <animate id="project_anim3" attributeName="fill" fill="freeze"
        values="#ECEFF1; #CD9900; #CD9900; #ECEFF1;"  keyTimes="0; 0.25; 0.70; 1" begin="project_anim2.end-1.2s" dur="2s"
        />
      </path>
      <rect class="shadow"  x="50%" y="0%" width="15%" height="2%" fill="#ECEFF1">
        <animate attributeType="XML" attributeName="height"
          values="99%; 2%;" begin="project_anim2.end-1.2s" dur="2s"
          />
      </rect>  
     </g>
     <g id="g836">
      
    <path id="path832" fill="none" class="st1" d="m768.8 543.6v-5.2c0-19.2 9.8-36.7 25.5-47.7 17.2-12.1 28.4-32.2 27.9-55-0.8-33.5-27.5-61.5-61-63.6-37.9-2.4-69.4 27.7-69.4 65.1 0 22.2 11.1 41.9 28.1 53.6 15.8 11 25.3 28.9 25.3 48.2v5.2c0 19.2-9.8 36.7-25.5 47.7-17.2 12-28.4 32.2-27.9 55 0.7 33.5 27.5 61.5 61 63.6 37.9 2.4 69.4-27.7 69.4-65.1 0-22.2-11.1-41.9-28.1-53.6-15.8-11-25.3-29-25.3-48.2z">
      <animate id="project_anim4" attributeName="fill" fill="freeze"
      values="#ECEFF1; #004d40; #004d40; #ECEFF1; " keyTimes="0; 0.25; 0.70; 1"  begin="project_anim3.end-1.2s" dur="2s"
        />
      </path>
      <rect class="shadow"  x="64%" y="0%" width="15%" height="2%" fill="#ECEFF1">
        <animate attributeType="XML" attributeName="height"
        values="99%; 2%;" begin="project_anim3.end-1.2s" dur="2s"
          />
      </rect>
      <path id="path834" fill="none" class="st0" d="m311.2 543.6v-5.2c0-19.2-9.8-36.7-25.5-47.7-17.2-12.1-28.4-32.2-27.9-55 0.8-33.5 27.5-61.5 61-63.6 37.9-2.4 69.4 27.7 69.4 65.1 0 22.2-11.1 41.9-28.1 53.6-15.8 11-25.3 28.9-25.3 48.2v5.2c0 19.2 9.8 36.7 25.5 47.7 17.2 12 28.4 32.2 27.9 55-0.7 33.5-27.5 61.5-61 63.6-37.9 2.4-69.4-27.7-69.4-65.1 0-22.2 11.1-41.9 28.1-53.6 15.8-11 25.3-29 25.3-48.2z">
        <animate id="project_anim1" attributeName="fill"
        fill="freeze"
        values="#ECEFF1; #CD9900; #CD9900; #ECEFF1;"  keyTimes="0; 0.25; 0.70; 1" begin="0s;project_anim4.end-1.2s" dur="2s"
        
        />
      </path>
      <rect class="shadow"  x="21%" y="0%" width="15%" height="2%" fill="#ECEFF1">
        <animate attributeType="XML" attributeName="height"
        repeatCount="indefinite"

          values="99%; 2%;" begin="0s;project_anim4.end-1.2s" dur="2s"
          />
      </rect>
     </g>
    </g>
   </svg>
  </template>
  
  <script>
  export default {
    name: 'App',
    data(){
      return {
      }
    }
  }
  </script>