<template>
<flowy-drag-handle >
    <v-hover v-slot="{ hover }">
        <v-card :data-cy="`flowyBlock${title.replaceAll(' ', '')}`" :elevation="hover ? 5 : 0" :class="{ 'on-hover': hover }" flat color="accent" :disabled="title === 'Apply Enrichment'">
            <v-row>
                <v-col cols="8">
                    <v-card-title>{{ title }}</v-card-title>
                    <v-card-subtitle>{{text}}</v-card-subtitle>
                </v-col>
                <v-col cols="4">
                    <v-icon color="primary" size="50" class="ml-3 mt-4 mb-n1" v-show="title === 'Apply AI Model'">fa-thin fa-brain-circuit</v-icon>
                    <v-icon color="primary" size="50" class="ml-3 mt-4 mb-n1" v-show="title === 'Apply Enrichment'">fa-thin fa-square-plus</v-icon>
                    <v-icon color="primary" size="50" class="ml-3 mt-4 mb-n1" v-show="title === 'Apply Transformation'">fa-thin fa-gears</v-icon>
                </v-col>
            </v-row>
        </v-card>
    </v-hover>
</flowy-drag-handle>
</template>

<script>
export default {
    name: 'DemoBlock',
    props: {
        title:{ type: String, default:''},
    },    
    data() {
        return {
            text: 'DRAG TO ADD'
        }
    }
}
</script>
