<template>
<v-container>
   <v-row>
      <v-col cols="12">
         <v-select :data-cy="`triggerSelectTransformationType${node.demoNodeIndex}`" item-color="primary" color="primary" v-model="type" class="mr-3 ml-3" dense outlined label="Select Transformation" :items="items" item-text="label" item-value="id"></v-select>
      </v-col>
   </v-row>
   <JoinNode v-if="type === 1" :node="node" />
   <AppendNode v-if="type === 2" :node="node" />
   <AggregateNode v-if="type === 3" :node="node" />
   <CalculateNode v-if="type === 4" :node="node" />
   <WhereNode v-if="type === 5" :node="node" />
   <TagNode v-if="type === 6" :node="node" />
   <RemoveColumnsNode v-if="type === 7" :node="node" />
   <RenameNode v-if="type === 8" :node="node" />
   <DateTimeNode v-if="type === 9" :node="node" />
   <ConvertToNullNode v-if="type === 10" :node="node" />
   <ConvertToZeroNode v-if="type === 11" :node="node" />
   <PivotNode v-if="type === 12" :node="node" />
   <CustomSql v-if="type === 13" :node="node" />
   <ChangeDataType v-if="type === 14" :node="node" />
</v-container>
</template>

<script>
import JoinNode from './JoinNode.vue'
import AggregateNode from './AggregateNode.vue'
import WhereNode from './WhereNode.vue'
import AppendNode from './AppendNode.vue'
import CalculateNode from './CalculateNode.vue'
import TagNode from './TagNode.vue'
import RemoveColumnsNode from './RemoveColumnsNode.vue'
import RenameNode from './RenameNode.vue'
import DateTimeNode from './DateTimeNode.vue'
import ConvertToNullNode from './ConvertToNullNode.vue'
import ConvertToZeroNode from './ConvertToZeroNode.vue'
import PivotNode from './PivotNode.vue'
import CustomSql from './CustomSql.vue'
import ChangeDataType from './ChangeDataTypeNode.vue'

export default {
   name: 'TransformNode',
   props: {
      node: {
         type: Object,
         default: () => {}
      },
   },
   components: {
      JoinNode,
      AggregateNode,
      WhereNode,
      AppendNode,
      CalculateNode,
      TagNode,
      RemoveColumnsNode,
      RenameNode,
      DateTimeNode,
      ConvertToNullNode,
      ConvertToZeroNode,
      PivotNode,
      CustomSql,
      ChangeDataType
   },
   data() {
      return {
         text: 'This is component A',
         dialog: false,
         type: '',
         items: [{
               id: 1,
               label: 'Join'
            },
            {
               id: 2,
               label: 'Append'
            },
            {
               id: 3,
               label: 'Aggregate'
            },
            {
               id: 4,
               label: 'Calculate'
            },
            {
               id: 5,
               label: 'Filter'
            },
            {
               id: 6,
               label: 'Tag'
            },
            {
               id: 7,
               label: 'Remove Columns'
            },
           /* {
               id: 8,
               label: 'Rename Columns'
            },*/
            {
               id: 9,
               label: 'Date Time Extraction'
            },
            {
               id: 10,
               label: 'Convert 0 to Null'
            },
            {
               id: 11,
               label: 'Convert Null to 0'
            },
            {
               id: 12,
               label: 'Pivot'
            },
            {
               id: 13,
               label: 'Custom SQl'
            },
            {
               id: 14,
               label: 'Change Data Types'
            }
         ]
      }
   },

   created() {
      
      if (this.node.data.step != null) {
         if(this.node.data.step.type === 'remove'){
             this.type = 7
         } else if(this.node.data.step.type === 'rename'){
             this.type = 8
         } else if(this.node.data.step.type === 'datetime'){
             this.type = 9
         } else if(this.node.data.step.type === 'convertnull'){
             this.type = 10
         } else if(this.node.data.step.type === 'convertzero'){
             this.type = 10
         } else if(this.node.data.step.type === 'customsql'){
             this.type = 13
         } else if(this.node.data.step.type === 'changedatatype'){
             this.type = 14
         } else {
            let index = this.items.findIndex(item => item.label.toLowerCase() === this.node.data.step.type)
            this.type = this.items[index].id
         }
         
      } 
   },
   methods: {}
}
</script>
