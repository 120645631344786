import axios from 'axios';
import {userStore} from '../stores/UserStore';

let baseUrl = 'https://api.ivp.ai/v1';
if (process.env.VUE_APP_ENV === 'DEV') {
   baseUrl = 'http://localhost:4002/v1';
} else if (process.env.VUE_APP_ENV === 'STAGE') {
   baseUrl = 'https://stage-api.ivp.ai/v1';
} else if (process.env.VUE_APP_ENV === 'PROD') {
   baseUrl = process.env.VUE_APP_API_URL;
}

const apiClient = axios.create({
baseURL: baseUrl,
   withCredential: false,
});

apiClient.interceptors.request.use(
   (config) => {
      const user = userStore();

      config.headers = {
         Authorization: user.getToken,
         'Content-Type': 'application/json',
         'Accept': 'application/json',
         'Allow': 'GET,PUT,POST,DELETE',
         'X-RateLimit-Limit': '100',
         'X-Content-Type-Options': 'nosniff',
      };
      return config;
   },
   (error) => {
      Promise.reject(error);
   }
);


export default {

   /*
   prompt(chat) {
		return apiClient.post('/chat/prompt', {
            chat
        });
   },*/
   
   chat(userId, userPrompt, tables, promptType, workspaceId, chatId) {
		return apiClient.post('/chat/chat', {
            userId, userPrompt, tables, promptType, workspaceId, chatId
        });
   },
   
   getChat(chatId) {
      return apiClient.get(`/chat/log/${chatId}`);
   },

   getChats(userId, workspaceId) {
      return apiClient.get(`/chat/chats/${userId}/${workspaceId}`);
   },

   rank(logId, ranking) {
      return apiClient.post('/chat/rank', {
         logId, ranking
      });
   },

    test(){ return apiClient.get('/chat/test')}

} 