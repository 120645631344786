<template>
<v-container>
   <v-row>
      <v-col cols="12">
         <v-select @change="update()" :data-cy="`triggerSelectTransformationType${node.demoNodeIndex}`" item-color="primary" color="primary" v-model="typeObj" class="mr-3 ml-3" dense outlined label="Select Transformation" :items="items" item-text="label" item-value="type"></v-select>
      </v-col>
   </v-row>
   <ForecastNode v-if="type === 'forecast'" :node="node" />
   <ClusteringNode v-if="type === 'clustering'" :node="node" />
   <FillNaNode v-if="type === 'fill na' || type === 'fill numerical na' || type === 'fill categorical na'" :node="node" />
   <DimensionalityReductionNode v-if="type === 'reduce complexity'" :node="node" />
</v-container>
</template>

<script>
import ForecastNode from './ForecastNode.vue'
import ClusteringNode from './ClusteringNode.vue'
import FillNaNode from './FillNaNode.vue'
import DimensionalityReductionNode from './DimensionalityReductionNode.vue'

export default {
   name: 'AiNode',
   props: {
      node: {
         type: Object,
         default: () => {}
      },
   },
   components: {
      ForecastNode, ClusteringNode, FillNaNode, DimensionalityReductionNode
   },
   data() {
      return {
         dialog: false,
         typeObj: null,
         type: null,
         items: [
            {
               type: ['forecast'],
               label: 'Forecast'
            },
            {
               type: ['clustering'],
               label: 'Group'
            },
            {
               type: ['fill na', 'fill categorical na', 'fill numerical na'],
               label: 'Missing Values'
            },
            {
               type: ['reduce complexity'],
               label: 'Reduce Complexity'
            },
         ]
      }
   },

   created() {
      
      if (this.node.data.step != null) {
         this.type = this.node.data.step.type
         let index = this.items.findIndex(item => item.type.includes(this.type))
         this.typeObj = this.items[index]
      }

   },

   methods: {
      update() {

         this.type = this.typeObj[0]

      }
   }

}
</script>
