'use strict';

//Global form validation rules

//// IMPLEMENTATION ////
////// template
// You need to put this in the component you want validated
// @input="onChange('password')"
// 'password' should be a unique identifier in your form
// the example above will check that the password field isn't empty

////// script

// example script block you will need in your component:

// import formRules from '@/utils/validation/formRules'

// export default {
//     mixins:[formRules],
//     data () { return {
//         errors:{},
//         formFields: {
//             orgName: {value:null, rule:'notEmpty'},
//             firstName: {value:null, rule:'notEmpty'},
//             lastName: {value:null, rule:'notEmpty'},
//             email: {value:null, rule:'notEmpty'},
//             password: {value:null, rule:'notEmpty'},
//         },
//     }},
//     computed: {
//         isSubmitDisabled(){
//             if (Object.keys(this.errors).length > 0){
//                 return true
//             } else { 
//                 return false
//             }
//         }
//     },
//     methods: {
//         onChange(fieldName) {
//            const inputErrors = this.validateField(this.formFields, fieldName);
//             if(inputErrors && inputErrors.length) {
//                 this.errors[fieldName] = inputErrors;
//             } else {
//                 this.errors[fieldName] = null;
//             }
//         },

//// FORM HINTS ////
// for pop-up hints underneath the component:
// :hint="errors.password?.join(' - ')"
// this mixin will return a list of arrays in an errors object, e.g.
// errors : { password :[] }

//// EXAMPLE RULES ////
// v => !!v  // error if null§
// v => v.length <= 10 // error if less than specified length (i.e. 10)error if not valid email address
// v => /\S+@\S+\.\S+/.test(v) // error if not valid email address
// v => ^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])$ must contain one upper case, one lower case, one digit, one special character

////////////////////////////

const formValidation = {

    data () { 

        return {

            comparisonVariable:null,
            compareValue:null,
            validationRules: {
                notEmpty: {
                    rules: [
                    value => !!value || 'This field is required.',
                    value => (value || '' ).length >= 1 || 'This field needs more detail.'        
                    ]
                },
                notNull: {
                    rules: [
                    value => !!value || 'This field is required.',
                    ]
                },
                notEmptyArray: {
                    rules: [
                    value => !!value || 'This field is required.',
                    value => (value || []).length >= 1 || 'You must pick at least one item.'        
                    ]
                },
                notEmptyObject: {
                    rules: [
                    value => !!value || 'This field is required.',
                    value => Object.keys(value).length > 0 || 'You must pick at least one item.'        
                    ]
                },
                password: {
                    rules: [
                    value => !!value || 'This field is required.',
                    value => (value || '' ).length >= 6 || 'Passwords must be at least 6 characters long',
                    value => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/.test(value) || 'Passwords must contain one upper case letter, one lower case letter, one digit, and one special character.',
                    ]
                },
                alphaNumeric: {
                    rules: [
                    value => !!value || 'This field is required.',
                    value => (value || '' ).length >= 3 || 'This field must be at least 3 characters long',
                    value => !/[^a-z0-9-_ ]/i.test(value) || 'This field can only include letters, numbers, dashes, spaces, and underscores.',
                    ]
                },
                onlyLettersAndWhitespace: {
                    rules: [
                    value => !!value || 'This field is required.',
                    value => (value || '' ).length >= 3 || 'This field must be at least 3 characters long',
                    value => !/[^a-z-_ ]/i.test(value) || 'This field can only include letters, dashes, spaces, and underscores.',
                    ]
                },
                onlyLetters: {
                    rules: [
                    value => !!value || 'This field is required.',
                    value => (value || '' ).length >= 3 || 'This field must be at least 3 characters long',
                    value => !/[^a-z-_]/i.test(value) || 'This field can only include letters, dashes, spaces, and underscores.',
                    ]
                },
                onlyNumbers: {
                    rules: [
                    value => !!value || 'This field is required.',
                    value => !/[^0-9]/i.test(value) || 'This field is numeric.',
                    ]
                },
                onlyLettersAndNumbers: {
                    rules: [
                    value => !!value || 'This field is required.',
                    value => (value || '' ).length >= 3 || 'This field must be at least 3 characters long',
                    value => !/[^a-z0-9-_]/i.test(value) || 'This field can only include letters, numbers, dashes, and underscores.',
                    ]
                },
                minText: {
                    rules: [
                    value => !!value || 'This field is required.',
                    value => (value || '' ).length >= 1 || 'This field must be at least 1 characters.'
                    ]
                },
                email: {
                    rules: [
                    value => /\S+@\S+\.\S+/.test(value) || 'Please enter a valid email address.'
                    ]
                },
                // comparison rules - must also pass a comparison value from the form and another form reference to compare against
                compareArray : {
                    rules: [
                        value => (value.length + this.comparisonVariable.length >= this.compareValue) || `These fields must contain at least ${this.compareValue} items between them.`,        
                    ]
                }
            }

        }      

    },

    methods: {

        testForErrors(rawFormFields, rules, fieldName, errors, index = -1){

            const formFields = structuredClone(rawFormFields)

            let obj = {
                value: formFields[fieldName],
                rule: rules[fieldName].rule
            }

            if (rules[fieldName].compareAgainst) {
                obj['compareAgainst'] = rules[fieldName].compareAgainst
                obj['compareValue'] = rules[fieldName].compareValue

                if (!formFields[obj.compareAgainst].value) {
                    formFields[obj.compareAgainst] = {
                        value: []
                    }
                }
            }

            formFields[fieldName] = obj

            const inputErrors = this.validateField(formFields, fieldName);

            if (!errors.fieldName && index > -1){
                errors[fieldName] = []
            }

            if (inputErrors && inputErrors.length) {
                if (index > -1){
                    errors[fieldName][index] = inputErrors;
                    if (formFields[fieldName][index] && formFields[fieldName][index].compareAgainst) {
                    errors[formFields[fieldName][index].compareAgainst] = inputErrors
                    }
                } else {
                    errors[fieldName] = inputErrors;
                    if (formFields[fieldName].compareAgainst) {
                        errors[formFields[fieldName].compareAgainst] = inputErrors
                    }
                }

            } else {

                if (index > -1){
                    errors[fieldName][index] = null;
                    if (formFields[fieldName][index] && formFields[fieldName][index].compareAgainst) {
                        errors[formFields[fieldName][index].compareAgainst] = null
                    }
                    } else {
                        errors[fieldName] = null;
                        if (formFields[fieldName].compareAgainst) {
                            errors[formFields[fieldName].compareAgainst] = null
                    }
                }

            }

            return errors

        },

        validateSpecificField(field){
            let returnArray = this.validationRules[field.rule]
                .rules
                .filter(rule => {
                    if (field.compareAgainst) { 
                        this.comparisonVariable = field.compareAgainst.value
                        this.compareValue = field.compareValue
                    }

                    const isValid = rule(field.value);

                    if (isValid !== true) {
                    return isValid;
                    }
                })
                .map(rule => rule(field.value))

            return returnArray

        },

        validateField(form, formItem) {
            let returnArray = this.validationRules[form[formItem].rule]
                .rules
                .filter(rule => {
                    if (form[formItem].compareAgainst) { 
                        this.comparisonVariable = form[form[formItem].compareAgainst].value
                        this.compareValue = form[formItem].compareValue
                    }

                    const isValid = rule(form[formItem].value);

                    if (isValid !== true) {
                        return isValid;
                    }
                }).map(rule => rule(form[formItem].value))

            return returnArray

        },

        validateForm(form) {
            const formErrors = {};
            let formIsValid = true;

            for(let property in form) {
                
                if (Object.prototype.hasOwnProperty.call(form, property)) {

                    const errors = this.validateField(form, property);

                    if(errors.length) {
                        formIsValid = false;
                    }
                
                    formErrors[property] = errors;

                }
            }

            formErrors.formIsValid = formIsValid;

            return formErrors;

        },

    }

}

export default formValidation