import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VuePapaParse from 'vue-papa-parse';
import VueApexCharts from 'vue-apexcharts';
import FlowyPlugin from "@hipsjs/flowy-vue";
import "@hipsjs/flowy-vue/dist/lib/flowy-vue.css";
import DemoNode from './components/pipelines/DemoNode.vue';
import DemoBlock from './components/pipelines/DemoBlock.vue';
//import colors from 'vuetify/lib/util/colors'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueMeta from 'vue-meta'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import { registerLicense } from '@syncfusion/ej2-base';

registerLicense(process.env.VUE_APP_KEY);
import { PivotViewPlugin,} from "@syncfusion/ej2-vue-pivotview";
Vue.use(PivotViewPlugin);
import {
  createPinia,
  PiniaVuePlugin
} from 'pinia'
import { userStore } from "@/stores/UserStore";

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

Vue.use(pinia)

// name is optional
Vue.use(VueLodash, { lodash })

Vue.use(VuePapaParse)

Vue.use(VueMeta)

Vue.config.productionTip = false

Vue.use(VueApexCharts)
Vue.component('apex-chart', VueApexCharts)
window.Apex.chart = {
  fontFamily: "Quicksand, sans-serif"
};

// eslint-disable-next-line
Apex.colors = ['#004D40','#CD9900', '#007864', '#f8c223', '#00a488', '#f9c93e', '#00cfac', '#fad15a', '#00fad0', '#fbd975', '#26ffda', '#fbe091', '#52ffe2',];


Vue.component('demo-block', DemoBlock)
Vue.component('demo-node', DemoNode)
Vue.use(FlowyPlugin);



new Vue({
  router,
  vuetify,
  pinia,
  render: h => h(App)
}).$mount('#app')

if(window.Cypress){
  const UserStore = userStore()
  window.__store__ = {UserStore}
  window.Vue = Vue;
}