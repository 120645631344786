<template>
<v-container>
   <v-form>
      <v-row>
         <v-col cols="12">
            <v-text-field data-cy="transformationCalculateTableTo" color="primary" :hint="errors.tableTo?.join(' - ')" outlined dense v-model="data.step.tableTo" label="Step Name" :error="(errors?.tableTo)?true:false" @input="onChange(data.step, 'tableTo')" persistent-hint></v-text-field>
         </v-col>

         <v-col cols="4" class="py-0">
            <v-select @focus="refreshCols()" dense label="Value to predict" outlined :hint="errors.numericalColumns?.join(' - ')" @input="onChange(data.step, 'numericalColumns')" :error="(errors?.numericalColumns)?true:false" :items="data.step.tableFromNumericalColumns" item-text="pretty_name" v-model="data.step.numericalColumns" return-object></v-select>
         </v-col>
         <v-col cols="8" class="py-0">
            <v-container fluid class="py-0">
               <v-radio-group @focus="refreshCols()" v-model="data.step.aggregationMethod " row class="mt-1 justify-center">
                  <v-radio label="Sum" value="SUM"></v-radio>
                  <v-radio label="Count" value="COUNT"></v-radio>
                  <v-radio label="Unique Count" value="DISTINCT COUNT"></v-radio>
                  <v-radio label="Average" value="AVG"></v-radio>
               </v-radio-group>
            </v-container>
         </v-col>

         <v-col cols="6" class="py-0">
            <v-select @focus="refreshCols()" :hint="(data.step.tableFromDateColumns.length < 1)?'There are no date fields in this dataset':''" persistent-hint dense label="Date Field" outlined :items="data.step.tableFromDateColumns" item-text="pretty_name" v-model="data.step.dateColumn" return-object></v-select>
         </v-col>
         <v-col cols="6" class="py-0">
            <v-select dense label="Date Grouping" outlined :items="dateGroupingValues" item-text="label" item-value="value" v-model="data.step.temporalResolution"></v-select>
         </v-col>
         <v-col cols="12" class="mt-5 py-0">
            <v-slider :label="`Predict ${data.step.numPredictions} ${data.step.temporalResolution}${data.step.numPredictions === 1? '' : 's'}`" color="primary" min="1" :max="max" thumb-label="always" v-model="data.step.numPredictions"></v-slider>
         </v-col>

         <v-col cols="12">
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
            </v-card-actions>
         </v-col>
      </v-row>

   </v-form>
</v-container>
</template>

<script>
import {
   emitData
} from '@/utils/eventBus'
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'

export default {
   name: 'ForecastNode',
   mixins: [formRules, nodeCode],
   props: {
      node: {
         type: Object,
         default: () => {
            return {}
         }
      },
   },

   data() {

      return {

         data: {
            step: {},
            rules: {}
         },
         errors: {},
         dateGroupingValues: [{
            label: 'Day',
            value: 'Day'
         }, {
            label: 'Week',
            value: 'Week'
         }, {
            label: 'Month',
            value: 'Month'
         }, ],

      }

   },

   created() {

      this.processParentData();
      this.data.step.type = 'forecast'

   },

   computed: {

      max() {

         if (this.data.step.temporalResolution === 'Day') {
            return 365
         } else if (this.data.step.temporalResolution === 'Week') {
            return 52
         } else if (this.data.step.temporalResolution === 'Month') {
            return 12
         } else if (this.data.step.temporalResolution === 'Quarter') {
            return 4
         } else {
            return 365
         }

      },

      disabledRules() {

         let isDisabled = false
         if (!this.data.step.tableTo || !this.data.step.aggregationMethod || !this.data.step.dateColumn || !this.data.step.numPredictions) {
            isDisabled = true
         }

         return isDisabled

      },

   },

   methods: {

      emitDataAction() {

         this.data.step.tableToColumns = [
            this.data.step.dateColumn,
            this.data.step.numericalColumns,
            {
               column_name: 'prediction_' + this.data.step.numericalColumns.column_name,
               data_type_id: 3,
               pretty_name: 'Predciction ' + this.data.step.numericalColumns.pretty_name
            },
            {
               column_name: 'prediction_lower_' + this.data.step.numericalColumns.column_name,
               data_type_id: 3,
               pretty_name: 'Predciction Lower ' + this.data.step.numericalColumns.pretty_name
            },
            {
               column_name: 'prediction_upper_' + this.data.step.numericalColumns.column_name,
               data_type_id: 3,
               pretty_name: 'Predciction Upper ' + this.data.step.numericalColumns.pretty_name
            }
         ]

         emitData('emit-data', this.data);

      }
   }
}
</script>
