import axios from 'axios';
import {userStore} from '../stores/UserStore';

let baseUrl = 'https://api.ivp.ai/v1';
if (process.env.VUE_APP_ENV === 'DEV') {
   baseUrl = 'http://localhost:4002/v1';
} else if (process.env.VUE_APP_ENV === 'STAGE') {
   baseUrl = 'https://stage-api.ivp.ai/v1';
} else if (process.env.VUE_APP_ENV === 'PROD') {
	baseUrl = process.env.VUE_APP_API_URL;
}

const apiClient = axios.create({
baseURL: baseUrl,
   withCredential: false,
});

apiClient.interceptors.request.use(
(config) => {
      const user = userStore();
      config.headers = {
        Authorization: user.getToken,
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		'Allow': 'GET,PUT,POST,DELETE',
		'X-RateLimit-Limit': '100',
		'X-Content-Type-Options': 'nosniff',
      };
      return config;
   },
   (error) => {
      Promise.reject(error);
   }
);


export default {

	getMapping(datasetId) {
		return apiClient.get(`/datasets/mapping/get/${datasetId}`);
	},

	getMappingEmbed(datasetId, token) {
		return apiClient.get(`/datasets/mapping/getEmbed/${datasetId}/${token}`);
	},

	getMappingWorkspace(workspaceId) {
		return apiClient.get(`/datasets/mapping/workspace/${workspaceId}`);
	},

	getDatasets(workspaceId) {
		return apiClient.get(`/datasets/get/${workspaceId}`);
	},

   getPreview(datasetId, offset, searchTerm, searchType, searchColumn) {
		return apiClient.post(`/datasets/preview/${datasetId}/${offset}`, {
			searchType,
			searchTerm,
			searchColumn,
		});
	},
   
	getAll(datasetId) {
		return apiClient.post(`/datasets/all/${datasetId}`, {
			
		});
	}, 

   getSize(datasetId, searchTerm, searchType, searchColumn) {
		return apiClient.post(`/datasets/size/${datasetId}`, {
			searchType,
			searchTerm,
			searchColumn,
		});
	},

   analyseDelete(datasetId) {
      return apiClient.get(`/datasets/analyseDelete/${datasetId}`);
   },

   executeDelete(datasetId) {
		return apiClient.get(`/datasets/delete/${datasetId}`);
	},

   getColumnMeta(datasetId, columnName) {
		return apiClient.post('/datasets/columns/meta', {
			datasetId,
			columnName,
		});
	},

	downloadDataset(datasetId) {
		return apiClient.get(`/datasets/download/${datasetId}`,{responseType: "blob"});
	},

	exploreDataset(datasetId) {
		return apiClient.get(`/datasets/explore/${datasetId}`);
	},

	renameColumn(datasetId, mappingId, newColumnName) {
		return apiClient.post('/datasets/rename/'+datasetId, {
			newColumnName,
			mappingId,
		});
	},

	renameDisplayName(datasetId, newName) {
		return apiClient.post('/datasets/rename-display/'+datasetId, {
			newName,
			datasetId,
		});
	},

	saveExplorer(datasetId, name, rows, columns, values, filters, filterValues) {
		return apiClient.post('/datasets/explorer/add', {
			datasetId,
			name,
			rows,
			columns,
			values,
			filters,
			filterValues
		});
	},

	editExplorer(datasetId, name, rows, columns, values, filters, filterValues, datasetExploreId, calculatedFieldSettings, conditionalFormatSettings, formatSettings, 
        showColumnGrandTotals, showColumnSubTotals, showGrandTotals, showRowGrandTotals,
        showRowSubTotals, showSubTotals) {
		return apiClient.post('/datasets/explorer/edit/'+datasetExploreId, {
			datasetId,
			name,
			rows,
			columns,
			values,
			filters,
			filterValues,
			calculatedFieldSettings, conditionalFormatSettings, formatSettings, 
        showColumnGrandTotals, showColumnSubTotals, showGrandTotals, showRowGrandTotals,
        showRowSubTotals, showSubTotals
		});
	},

	getExplorerViews(datasetId) {
		return apiClient.get(`/datasets/explorer/get/${datasetId}`);
	},

	getCanRename(datasetId) {
		return apiClient.get(`/datasets/can/rename/${datasetId}`);
	}

}