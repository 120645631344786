<template>
  <v-container>
      <v-row>
        <v-col cols="6">
          <v-text-field color="primary" outlined dense v-model="data.step.tableTo" label="Step Name" @input="onChange(data.step, 'tableTo')" :hint="errors.tableTo?.join(' - ')" persistent-hint></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field color="primary" outlined dense v-model="data.step.newColumnName"
            label="Name of New Column" @input="onChange(data.step, 'newColumnName')" :hint="errors.newColumnName?.join(' - ')" persistent-hint></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select label="Currency Column" dense outlined color="accent" :items="data.step.tableFromNumericalColumns"
            item-text="pretty_name" item-value="column_name" multiple small-chips
            v-model="data.step.inputColumnName" @input="onChange(data.step, 'inputColumnName')" :hint="errors.inputColumnName?.join(' - ')" persistent-hint></v-select>
        </v-col>
        <v-col cols="6">
          <v-select dense label="Date Field" outlined :items="data.step.tableFromDateColumns" item-text="pretty_name"
            item-value="column_name" v-model="data.step.dateVar" @input="onChange(data.step, 'dateVar')" :hint="errors.dateVar?.join(' - ')" persistent-hint></v-select>
        </v-col>
        <v-col cols="6">
          <v-select label="Convert From" dense outlined color="accent" :items="data.currencies" item-text="pretty_name"
            item-value="currency" small-chips v-model="data.step.convertFrom" @input="onChange(data.step, 'convertFrom')" :hint="errors.convertFrom?.join(' - ')" persistent-hint></v-select>
        </v-col>
        <v-col cols="6">
          <v-select label="Convert To" dense outlined color="accent" :items="data.currencies" item-text="pretty_name"
            item-value="currency" small-chips v-model="data.step.convertTo" @input="onChange(data.step, 'convertTo')" :hint="errors.convertTo?.join(' - ')" persistent-hint></v-select>
        </v-col>

        <v-col cols="12">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="emitDataAction" color="primary" :disabled="disabledRules">Save</v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
  </v-container>
</template>
  
<script>
import { emitData } from '@/utils/eventBus'
const { v4: uuidv4 } = require('uuid');
import formRules from '@/utils/validation/formRules'

export default {
  name: 'currencyConversionNode',
  mixins: [formRules],
  props: {
    node: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      errors:{},
      dataStepForValidation:null,
      data: {
        step: {
          type: 'enrichment',
          modelType: 'currencyConversion',
          tableFrom: null,
          tableFromDatabaseName: null,
          tableFromColumns: null,
          tableFromNumericalColumns: [],
          tableFromCategoricalColumns: [],
          tableFromDateColumns: [],

          tableTo: null,
          tableToDatabaseName: uuidv4().replace(/-/g, '_').toLowerCase(),
          tableToColumns: null,

          newColumnName: null,
          inputColumnName: null,
          dateVar: null,
          convertFrom: null,
          convertTo: null,
        },
        rules: {
            tableTo: {rule : 'minText'},
            newColumnName: {rule : 'minText'},
            inputColumnName: {rule : 'notEmptyArray'},
            dateVar: {rule : 'notEmptyArray'},
            convertFrom: {rule : 'notEmpty'},
            convertTo: {rule : 'notEmpty'},
        },
        currencies: [
          { currency: 'gbp', pretty_name: 'Great British Pound' },
          { currency: 'eur', pretty_name: 'Euro' },
          { currency: 'usd', pretty_name: 'US Dollars' },
          { currency: 'aud', pretty_name: 'Australian Dollars' },
          { currency: 'cad', pretty_name: 'Canadian Dollars' },
          { currency: 'chf', pretty_name: 'Swiss Francs' },
          { currency: 'dkk', pretty_name: 'Danish Krone' },
          { currency: 'hkd', pretty_name: 'Hong Kong Dollars' },
          { currency: 'ils', pretty_name: 'Israeli New Shekel' },
          { currency: 'jpy', pretty_name: 'Japanese Yen' },
          { currency: 'nzd', pretty_name: 'New Zealand Dollar' },
          { currency: 'nok', pretty_name: 'Norwegian Krone' },
          { currency: 'pln', pretty_name: 'Polish Zloty' },
          { currency: 'zar', pretty_name: 'South African Rand' },
          { currency: 'ron', pretty_name: 'Romanian Leu' },
          { currency: 'aed', pretty_name: 'United Arab Emirates Dirham' },
          { currency: 'rub', pretty_name: 'Russian Ruble' },
          { currency: 'sar', pretty_name: 'Saudi Riyal' },
          { currency: 'sgd', pretty_name: 'Singapore Dollar' },
          { currency: 'mxn', pretty_name: 'Mexican Peso' },
          { currency: 'thb', pretty_name: 'Thai Baht' },
          { currency: 'czk', pretty_name: 'Czech Koruna' },
          { currency: 'huf', pretty_name: 'Hungarian Forint' }
        ],
        node: this.node,
      },
    }
  },

  created() {
    if (this.node.parentId !== -1) {

      this.data.step.tableFrom = this.node.parentData.tableTo;
      this.data.step.tableFromDatabaseName = this.node.parentData.tableToDatabaseName;

      this.data.step.tableFromColumns = structuredClone(this.node.parentData.tableToColumns);
      this.data.step.tableFromNumericalColumns = this.node.parentData.tableToColumns.filter(item => item.data_type_id === 1)
      this.data.step.tableFromCategoricalColumns = this.node.parentData.tableToColumns.filter(item => item.data_type_id === 2)
      this.data.step.tableFromDateColumns = this.node.parentData.tableToColumns.filter(item => item.data_type_id === 3)
      
    }
    if (this.node?.data?.step??null !== null) {
      this.data.step = this.node.data.step
    }
  },
  computed: {
        disabledRules(){
            let isDisabled = false;
            for (const key in this.errors) {
                if (this.errors[key] != null) {
                    isDisabled = true
                }
            }
            if  (
                    !this.data.step.tableTo ||
                    !this.data.step.newColumnName ||
                    this.data.step.inputColumnName?.length < 1  ||
                    !this.data.step.dateVar  ||
                    !this.data.step.convertFrom  ||
                    !this.data.step.convertTo
                ) {
                    isDisabled = true
                }
            return isDisabled;
        },
    },

  methods: {

    onChange(location, fieldName) {
        this.errors = this.testForErrors(location, this.data.rules, fieldName, this.errors)
      },

    emitDataAction() {

      this.data.step.tableToColumns = structuredClone(this.data.step.tableFromColumns);

      const obj = {
        data_type_id: 1, // get from tag sele,   
        column_name: this.data.step.newColumnName.replace(/\s/g, '_').toLowerCase(), // string replace,
        pretty_name: this.initCap(this.data.step.newColumnName)
      };

      this.data.step.tableToColumns.push(obj);

      emitData('emit-data', this.data);

    },
    initCap(word) {
      return word.toLowerCase().replace(/(?:^|\s)[a-z]/g, m => m.toUpperCase());
    }

  }
}
</script>