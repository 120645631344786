<template>
    <v-container>
    <v-row>
        <v-col cols="12">
            <v-select item-color="primary" color="primary" v-model="type" class="mr-3 ml-3" dense outlined label="Select Enrichment" :items="items" item-text="label" item-value="id"></v-select>
        </v-col>
    </v-row>
    <currencyConversion v-if="type === 4" :node="node" />
    <postcodeConversion v-if="type === 1" :node="node" />
    </v-container>
    </template>

<script>
import currencyConversion from './CurrencyConversionView.vue'
import postcodeConversion from './PostcodeConversion.vue'

export default {
    name: 'BaseDatasetNode',
    props: {
        node:{ type: Object, default: () => {return {}}}
    },    
    components: {currencyConversion, postcodeConversion},
    data() {
        return {
            text: 'This is component A',
            dialog: false,
            type: null,
            items: [
                {id: 1, label: 'Geolocations'},
                {id: 2, label: 'Text Sentiment'},
                {id: 3, label: 'Text Entities'},
                {id: 4, label: 'Currency Conversion'},
            ]
        }
    },

    created(){

        this.refreshCols()
       
    },

    methods: {

        refreshCols() {

            if (this.node.parentId !== -1 && this.node.data.step !== undefined) { // the step already exists (load existing data)

                this.data.step = this.node.data.step
                let index = this.items.findIndex(item => item.label.toLowerCase() === this.node.data.step.type.toLowerCase())
                this.type = this.items[index].id
                this.data.step.tableFrom = this.node.parentData.tableTo;
                this.data.step.tableFromDatabaseName = this.node.parentData.tableToDatabaseName;
                this.data.step.tableFromColumns = JSON.parse(JSON.stringify(this.node.parentData.tableToColumns));

            } else if (this.node.parentId !== -1 && this.node.data.step === undefined) { // a new step (load parent data only)

                let index = this.items.findIndex(item => item.label.toLowerCase() === this.node.data.step.type.toLowerCase())
                this.type = this.items[index].id
                this.data.step.tableFrom = this.node.parentData.tableTo;
                this.data.step.tableFromDatabaseName = this.node.parentData.tableToDatabaseName;
                this.data.step.tableFromColumns = JSON.parse(JSON.stringify(this.node.parentData.tableToColumns));

            }
        }
    }
}
</script>
