<template>
<v-container>
   <v-row>
      <v-col cols="12">
         <v-text-field color="primary" :hint="errors.tableTo?.join(' - ')" outlined dense v-model="data.step.tableTo" label="Step Name" :error="(errors?.tableTo)?true:false" @input="onChange(data.step, 'tableTo')"></v-text-field>

      </v-col>
   </v-row>
   <v-row>
      <v-col cols="4">
         <v-select :data-cy="`dataset${node.demoNodeIndex}`" @focus="refreshCols()" item-color="primary" color="primary" v-model="data.step.tableFrom2Complete" @change="getColumns()" dense outlined label="Select Dataset to Append" :items="allDatasets" item-text="pretty_name" return-object :error="(errors?.tableFrom2Complete)?true:false" @input="onChange(data.step, 'tableFrom2Complete')" :hint="errors.tableFrom2Complete?.join(' - ')" persistent-hint item-disabled="acyclic"></v-select>
      </v-col>
   </v-row>

   <v-row>
      <v-col cols="12">
         <v-card-title class="px-0 text-body-1">
            Original Dataset
            <v-spacer />
            <v-btn @click="addColumn()" color="primary">
               <v-icon>fa-thin fa-plus</v-icon>
            </v-btn>
         </v-card-title>
         <v-simple-table>
            <template v-slot:default>
               <thead>
                  <tr>
                     <th v-for="(item, index) in data.step.tableFrom1ColumnsAppend" :key="index" class="text-centre px-0 pr-3" style="max-width: 200px !important; min-width: 200px !important;">
                        <v-card-actions class="px-0">
                           <v-btn v-if="data.step.tableFrom1ColumnsAppend.length > 1" small @click="removeColumn(index)" color="primary">
                              Remove Column
                              <v-icon right>fa-thin fa-minus</v-icon>
                           </v-btn>
                        </v-card-actions>
                        <v-select class="mt-3" item-color="primary" color="primary" dense outlined :label="'Column ' + (index + 1)" :items="data.step.tableFrom1Columns" item-text="pretty_name" item-value="column_name" v-model="data.step.tableFrom1ColumnsAppend[index]" return-object></v-select>
                     </th>
                  </tr>
               </thead>
            </template>
         </v-simple-table>
         <v-card-title class="text-body-1 px-0">Appended Dataset</v-card-title>
         <v-simple-table>
            <template v-slot:default>
               <thead>
                  <tr>
                     <th v-for="(item, index) in data.step.tableFrom2ColumnsAppend" :key="index" class="text-centre px-0 pr-3" style="max-width: 200px !important; min-width: 200px !important;">
                        <v-select class="mt-3" item-color="primary" color="primary" dense outlined :label="'Column ' + (index + 1)" :items="data.step.tableFrom2Columns" item-text="pretty_name" item-value="column_name" v-model="data.step.tableFrom2ColumnsAppend[index]" return-object></v-select>
                     </th>
                  </tr>
               </thead>
            </template>
         </v-simple-table>
      </v-col>

      <!--<v-col cols="2">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="addColumn" color="primary">
                    <v-icon>fa-thin fa-plus</v-icon>
                </v-btn>
            </v-card-actions>
        </v-col>-->
   </v-row>
   <v-row>
      <v-col cols="12">
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :data-cy="`saveButton${node.demoNodeIndex}`" @click="emitDataAction()" color="primary"> {{data.node.data.status === 'unconfigured' ? 'Save' : 'Update'}} </v-btn>
         </v-card-actions>
      </v-col>
   </v-row>
</v-container>
</template>

<script>
import pipelinesApi from '@/services/pipelines';
import {
   emitData
} from '@/utils/eventBus'
import formRules from '@/utils/validation/formRules'
import nodeCode from '@/utils/pipelines/nodeCode'
import {
   userStore
} from '@/stores/UserStore';

export default {
   name: 'AppendNode',
   mixins: [formRules, nodeCode],
   props: {
      node: {
         type: Object,
         default: () => {
            return {}
         }
      }
   },

   setup() {
      const user = userStore()
      return {
         user,
      }
   },

   data() {
      return {
         allDatasets: [],
         menu: false,
         errors: {},
         data: {
            step: null,
            rules: {
               tableTo: {
                  rule: 'notEmpty'
               },
               aggBy: {
                  rule: 'notEmptyArray'
               },
               aggs: {
                  rule: 'notEmptyArray'
               },
            }
         }
      }
   },

   async created() {

      this.processParentData();

      await pipelinesApi.getAcyclicDatasets(this.user.getCurrentWorkspace, this.data.step.schemaTo, this.data.step.tableToDatabaseName)
         .then(res => {
            this.allDatasets = res.data;
         })
         .catch(err => {
            console.error(err)
         })

      this.data.step.type = 'append'

   },

   computed: {
      disabledRules() {
         let isDisabled = false;
         for (const key in this.errors) {
            if (this.errors[key] != null) {
               isDisabled = true
            }
         }
         return isDisabled;
      },
   },
   methods: {

      addColumn() {

         this.data.step.tableFrom1ColumnsAppend.push({
            column_name: 'column 2',
            pretty_name: 'column 2',
            'data_type_id': 2
         })
         this.data.step.tableFrom2ColumnsAppend.push({
            column_name: 'column 2',
            pretty_name: 'column 2',
            'data_type_id': 2
         })

      },

      removeColumn(index) {

         if (this.data.step.tableFrom1ColumnsAppend.length > 1) {
            this.data.step.tableFrom1ColumnsAppend.splice(index, 1)
            this.data.step.tableFrom2ColumnsAppend.splice(index, 1)
         }


      },

      emitDataAction() {

         this.data.step.tableToColumns = structuredClone(this.data.step.tableFrom1ColumnsAppend);

         this.data.step.schemaFrom2 = this.data.step.tableFrom2Complete.schema;
         this.data.step.tableFrom2DatabaseName = this.data.step.tableFrom2Complete.name;

         emitData('emit-data', this.data);

      }
   }
}
</script>

<style scoped>
table {
   border-collapse: collapse
}
</style>
